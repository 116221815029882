import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdditionsList from "./AdditionsList";
import { SetCode, SetComent, SetOff } from "../store/OrderReducer";
import { RemoveOrderPosition, RemoveFromAmmount, RemoveObligatoryAdditions } from "../store/OrderReducer";
import { SetAlert } from "../store/UserReducer";
import Style from "../Style";
import "../CSS/TakeInfo.css";
import round from "../Functions/round";
import hexatorgb from "../Functions/hexatorgb";
export default function OrderList({ color }) {
  const Order = useSelector((e) => e.OrderReducer.order);
  const OffRedux = useSelector((e) => e.OrderReducer.off);
  const IsAPi = useSelector((a) => a.UserReducer.is_api);
  const CodeRedux = useSelector((e) => e.OrderReducer.code);
  const Ammount = useSelector((e) => e.OrderReducer.ammount);
  const Coment = useSelector((a) => a.OrderReducer.coment);
  const City = useSelector((e) => e.OrderReducer.city);
  const FreeAbove = useSelector((e) => e.OrderReducer.freeAbove);
  const Coupons = useSelector((e) => e.UserReducer.coupons);
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const [DeliveryCostIncluded, setDeliveryCostIncluded] = useState("");
  const ObligatoryAditions = useSelector((a) => a.OrderReducer.obligatoryAdditionsForComent);
  const [DeliveryRegionPrice, setDeliveryRegionPrice] = useState("");
  const [Color, setColor] = useState("");
  const [My_order_label, setMy_order_label] = useState("");
  const [Delete_from_basket_button, setDelete_from_basket_button] = useState(
    ""
  );
  const [Amount_text, setAmount_text] = useState("");
  const [Order_currency, setOrder_currency] = useState("");
  const [Promo_code_label, setPromo_code_label] = useState("");
  const [Comment_to_order, setComment_to_order] = useState("");
  const [Discount_text, setDiscount_text] = useState("");
  const [Delivery_is_free, setDelivery_is_free] = useState("");
  useEffect(() => {
    if (UserInfo.constant_texts !== undefined) {
      setDeliveryCostIncluded(UserInfo.constant_texts.delivery_cost_included);
      setDeliveryRegionPrice(UserInfo.constant_texts.delivery_region_price);
      setDelete_from_basket_button(
        UserInfo.constant_texts.delete_from_basket_button
      );
      setAmount_text(UserInfo.constant_texts.amount_text);
      setOrder_currency(UserInfo.constant_texts.order_currency);

      setDiscount_text(UserInfo.constant_texts.discount_text);
      setDelivery_is_free(UserInfo.constant_texts.delivery_is_free);
    }
    if (UserInfo.colors !== undefined) {
      setColor(hexatorgb(UserInfo.colors.additions_font_color, 15));
    }
    if (UserInfo.editable_texts !== undefined) {
      setMy_order_label(UserInfo.editable_texts.my_order_label);
      setPromo_code_label(UserInfo.editable_texts.promo_code_label);
      setComment_to_order(UserInfo.editable_texts.comment_to_order);
    }
  }, [UserInfo]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (CodeRedux !== "") {
      calculateDiscount(CodeRedux);
    }
  });
  const Code = useSelector((e) => e.OrderReducer.code);
  const CodeValid = (event) => {
    SetCode(event.target.value.toUpperCase(), dispatch);
    if (Coupons[event.target.value.toUpperCase()] !== undefined) {
      //  console.log("mam!!!!");

      calculateDiscount(event.target.value.toUpperCase());
    } else {
      // console.log("Nie mam");

      SetOff(0, dispatch);
    }
    // SetCode(event.target.value, dispatch);
    SetAlert(null, dispatch);
  };
  const calculateDiscount = (value) => {
    let price = 0;
    if (Coupons[value] !== undefined) {
      Object.keys(Order).map((element) => {
        // console.log(Order[element].mealPrise);
        let otp = Order[element];
        //   Order[element].idOption
        if (Coupons[value].meal.includes(otp.idOption)) {
          let p = (otp.mealPrise * Coupons[value].percent) / 100;
          // console.log(p);
          p = round(p);
          //   console.log(p);
          price = price + p;
        } else {
          // console.log("nie ten element");
        }
      });
    }

    SetOff(round(price), dispatch);
  };
  const ComentValid = (event) => {
    SetComent(event.target.value, dispatch);
    SetAlert(null, dispatch);
  };
  const inputStyle = {
    color: `${color}`,
    borderRadius: "0px",
    borderBottom: `1px solid ${Color}`,
  };
  return (
    <>
      <div className="col-12 px-md-5 px-4 pb-0 mt-md-5 mt-0" id="OrderList">
        <p className="Bold h1 upper Order-font1">{My_order_label}</p>
        {Object.keys(Order).map((element) => (
          <div className="row  py-2 border-bottom border-1">

            <div className="col-md-6 col-7 Bold px-0 upper ">
              {Order[element].name}{" "}
              {Order[element].optName === ""
                ? ""
                : "- " + Order[element].optName}
            </div>
            <div className="col-md-3 col-3 Lite px-0 ">
              {" "}
              {round(Order[element].localAmmount).toFixed(2)} {Order_currency}
            </div>
            <div className="col-md-3 col-2 Lite px-0">
              <button
                className="removeButton Lite px-0 upper"
                style={{
                  color: `${color}`,
                }}
                onClick={() => {
                  RemoveFromAmmount(Order[element].localAmmount, dispatch);
                  RemoveOrderPosition(element, dispatch);
                  if(ObligatoryAditions[element]!==undefined){
                    RemoveObligatoryAdditions(element, dispatch)
                  }
                }}
              >
                {Delete_from_basket_button}
              </button>
            </div>


            <div className="mb-0 col-12 px-0">
              {Object.keys(ObligatoryAditions).length>0 && ObligatoryAditions[element]!==undefined?
                Object.keys(ObligatoryAditions[element].obligatoryAdditions).map((key) => (
                  <span className="mr-1 Lite">
                    {ObligatoryAditions[element].obligatoryAdditions[key].name}
                  </span>
                )):''
                }
            </div>

            <div className="col-9 px-0 f-size-disc">
              <AdditionsList additionList={Order[element].additions} />
            </div>
          </div>
        ))}
        <div className="row  py-3">
          <div className="col-md-6 col-7 h5 Bold upper px-0 Order-font2">
            {Amount_text}
          </div>
          <div className="col-md-3 col-3 h5 Bold px-0 Order-font2">
            {" "}
            {City !== undefined &&
              City !== null && IsAPi === false &&

              Object.keys(Order).length > 0
              ? Ammount - OffRedux < FreeAbove
                ? round(Ammount + City.delivery_price - OffRedux).toFixed(2)
                : round(Ammount - OffRedux).toFixed(2)
              : round(Ammount - OffRedux).toFixed(2)}{" "}
            {Order_currency}
          </div>
          <div className="col-md-6 offset-md-6  lite px-0">
            {OffRedux !== 0
              ? `${Discount_text}: -${OffRedux.toFixed(
                2
              )}${" "}${Order_currency}`
              : ""}
          </div>
          <div className="col-md-6 offset-md-6  lite px-0">
            {City !== null && City !== undefined && IsAPi === false // api albo klasycznie 
              ? Ammount - OffRedux < FreeAbove
                ? `${DeliveryCostIncluded} ${City.delivery_price.toFixed(
                  2
                )}${" "}${Order_currency}`
                : `${Delivery_is_free}`
              : ""}
          </div>
        </div>
      </div>
      <div className="col-md-6 pl-md-5 pl-4 pr-sm-0 pr-4 pr-0">
        {Object.keys(Coupons).length === 0 ? (
          ""
        ) : (
          <input
            className="inputs Lite mb-3 upper"
            type="text"
            placeholder={Promo_code_label}
            value={Code}
            onChange={(e) => {
              CodeValid(e);
            }}
            style={inputStyle}
          />
        )}
        <textarea
          className="inputs Lite mb-3"
          type="text"
          placeholder={Comment_to_order}
          value={Coment}
          onChange={(e) => {
            ComentValid(e);
          }}
          style={inputStyle}
        />{" "}
      </div>
      <Style color={color} />
    </>
  );
}
