export const ADD_ORDER_POSITION = "ADD_ORDER_POSITION";
export const ADD_TO_AMMOUNT = "ADD_TO_AMMOUNT";
export const REMOVE_FROM_AMMOUNT = "REMOVE_FROM_AMMOUNT";
export const REMOVE_ORDER_POSITION = "REMOVE_ORDER_POSITION";
export const SET_DELIVERY = "SET_DELIVERY";
export const SET_CITY = "SET_CITY";
export const SET_CITY_API = "SET_CITY_API";
export const SET_STREET = "SET_STREET";
export const SET_BUILDNR = "SET_BUILDNR";
export const SET_NAME = "SET_NAME";
export const SET_SURNAME = "SET_SURNAME";
export const SET_PHONE = "SET_PHONE";
export const SET_EMAIL = "SET_EMAIL";
export const SET_CODE = "SET_CODE";
export const SET_COMENT = "SET_COMENT";
export const SET_DELIVERYAMMOUNT = "SET_DELIVERYAMMOUNT";
export const SET_FREEABOVE = "SET_FREEABOVE";
export const SET_OFF = "SET_OFF";
export const SET_AGREE = "SET_AGREE";
export const SET_AGREE2 = "SET_AGREE2";
export const SET_RANGE = "SET_RANGE";
export const SET_ORDER_BY_MYSELF = "SET_ORDER_BY_MYSELF";
export const SET_LAST_CITY = "SET_LAST_CITY";
export const SET_LAST_STREET = "SET_LAST_STREET";
export const SET_LAST_BUILDNR = "SET_LAST_BUILDNR";
export const SET_AT_LOCATION = "SET_AT_LOCATION";
export const SET_PLACE_ID = "SET_PLACE_ID";
export const SET_DEVICE_ID = "SET_DEVICE_ID";
export const SET_VALID_ADRESS = "SET_VALID_ADRESS";
export const ADD_OBLIGATORY_ADDITIONS = "ADD_OBLIGATORY_ADDITIONS";
export const REMOVE_OBLIGATORY_ADDITIONS = "REMOVE_OBLIGATORY_ADDITIONS";
const initialState = {
  place_id: null,
  valid_adress: '',
  order: {},
  delivery: {},
  ammount: 0,
  off: 0,
  deliveryAmmount: null,
  freeAbove: null,
  cityAPI: null,
  city: null,
  range: null,
  street: "",
  buildNr: "",
  name: "",
  surname: "",
  phone: "",
  email: "",
  code: "",
  obligatoryAdditionsForComent:{},
  coment: "",
  agree: false,
  agree2: false,
  order_by_myself: false,
  lastCity: null,
  lastStreet: null,
  lastBuildNr: null,
  atLocation: null,
  deviceID:'',
};
String.prototype.escapeDiacritics = function () {
  return this.replace(/ą/g, "a")
    .replace(/Ą/g, "A")
    .replace(/ć/g, "c")
    .replace(/Ć/g, "C")
    .replace(/ę/g, "e")
    .replace(/Ę/g, "E")
    .replace(/ł/g, "l")
    .replace(/Ł/g, "L")
    .replace(/ń/g, "n")
    .replace(/Ń/g, "N")
    .replace(/ó/g, "o")
    .replace(/Ó/g, "O")
    .replace(/ś/g, "s")
    .replace(/Ś/g, "S")
    .replace(/ż/g, "z")
    .replace(/Ż/g, "Z")
    .replace(/ź/g, "z")
    .replace(/Ź/g, "Z");
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "ADD_OBLIGATORY_ADDITIONS": {
      let newObligatory = state.obligatoryAdditionsForComent;
      let newID=action.payload[3]  
      newObligatory[newID] = {
        name: action.payload[0],
        optName: action.payload[1],
        obligatoryAdditions: action.payload[2],
      };
      //console.log(newObligatory);
      return {
        ...state,
        obligatoryAdditionsForComent: newObligatory,
      };
    }
    case "REMOVE_OBLIGATORY_ADDITIONS": {
      let newObligatory = state.obligatoryAdditionsForComent;
      delete newObligatory[action.payload];
      //console.log(newObligatory);
      return {
        ...state,
        obligatoryAdditionsForComent: newObligatory,
      };
    }
    case "ADD_ORDER_POSITION": {
      let newOrder = state.order;
      let newID=action.payload[3]
       //console.log(action);
      // console.log(action.payload);
   

      newOrder[newID] = {
        name: action.payload[0].name,
        idMeal: action.payload[0].idMeal,
        mealPrise: action.payload[0].price,
        optName: action.payload[0].optName,
        idOption: action.payload[0].idOption,
        additions: action.payload[1],
        localAmmount: action.payload[2],
      };
      //console.log(newOrder);
      return {
        ...state,
        order: newOrder,
      };
    }
    case "REMOVE_ORDER_POSITION": {
      let newOrder = state.order;
      let newAmmount = state.ammount;
      delete newOrder[action.payload];
      if (Object.keys(newOrder).length === 0) {
        newAmmount = 0;
      }
      return {
        ...state,
        order: newOrder,
        ammount: newAmmount,
      };
    }
    case "SET_RANGE": {
      return {
        ...state,
        range: action.payload,
      };
    }
    case "SET_DEVICE_ID": {
      return {
        ...state,
        deviceID: action.payload,
      };
    }
    case "SET_VALID_ADRESS": {
      return {
        ...state,
        valid_adress: action.payload,
      };
    }
    case "SET_LAST_CITY": {
      return {
        ...state,
        lastCity: action.payload,
      };
    }
    case "SET_PLACE_ID": {
      return {
        ...state,
        place_id: action.payload,
      };
    }
    case "SET_LAST_STREET": {
      return {
        ...state,
        lastStreet: action.payload,
      };
    }
    case "SET_LAST_BUILDNR": {
      return {
        ...state,
        lastBuildNr: action.payload,
      };
    }
    case "SET_AT_LOCATION": {
      return {
        ...state,
        atLocation: action.payload,
      };
    }

    case "SET_ORDER_BY_MYSELF": {
      return {
        ...state,
        order_by_myself: action.payload,
      };
    }
    case "ADD_TO_AMMOUNT": {
      let newAmmount = state.ammount + action.payload;
      // console.log(newAmmount);
      return {
        ...state,
        ammount: newAmmount,
      };
    }
    case "SET_DELIVERYAMMOUNT": {
      return {
        ...state,
        deliveryAmmount: action.payload,
      };
    }
    case "SET_FREEABOVE": {
      return {
        ...state,
        freeAbove: action.payload,
      };
    }
    case "REMOVE_FROM_AMMOUNT": {
      let newAmmount = state.ammount - action.payload;
      if (newAmmount < 0) {
        newAmmount = 0;
      }
      return {
        ...state,
        ammount: newAmmount,
      };
    }
    case "SET_DELIVERY": {
      return {
        ...state,
        delivery: action.payload,
      };
    }
    case "SET_OFF": {
      return {
        ...state,
        off: action.payload,
      };
    }
    //Inputs validation
    case "SET_CITY": {
      let newCity = action.payload;
      // console.log(newCity);
      return {
        ...state,
        city: newCity,
      };
    }
    case "SET_CITY_API": {
      let newCity = action.payload;
      // console.log(newCity);
      return {
        ...state,
        cityAPI: newCity,
      };
    }
    case "SET_STREET": {
      let newStreet = action.payload;
      // console.log(newStreet);
      return {
        ...state,
        street: newStreet,
      };
    }
    case "SET_BUILDNR": {
      let newBulidNr = action.payload;
      // console.log(newBulidNr);
      return {
        ...state,
        buildNr: newBulidNr,
      };
    }
    case "SET_NAME": {
      let label = new String(action.payload);
      let newName = label.escapeDiacritics();
      //console.log(newName);
      return {
        ...state,
        name: newName,
      };
    }
    case "SET_SURNAME": {
      let label = new String(action.payload);
      let newSurname = label.escapeDiacritics();
      // console.log(newSurname);
      return {
        ...state,
        surname: newSurname,
      };
    }
    case "SET_PHONE": {
      let newPhone = action.payload.replace(/\D/, "");
      if (newPhone.length <= 9) {
        return {
          ...state,
          phone: newPhone,
        };
      } else {
        return {
          ...state,
        };
      }

      // console.log(newPhone);
    }
    case "SET_EMAIL": {
      let label = new String(action.payload);
      let newEmail = label.escapeDiacritics();

      // console.log(newEmail);
      return {
        ...state,
        email: newEmail,
      };
    }
    case "SET_CODE": {
      let newCode = action.payload;
      // console.log(newCode);
      return {
        ...state,
        code: newCode,
      };
    }
    case "SET_COMENT": {
      let newComent = action.payload;
      //  console.log(newComent);
      return {
        ...state,
        coment: newComent,
      };
    }
    case "SET_AGREE": {
      let newAgree = action.payload;
      // console.log(newAgree);
      return {
        ...state,
        agree: newAgree,
      };
    }
    case "SET_AGREE2": {
      let newAgree = action.payload;
      //console.log(newAgree);
      return {
        ...state,
        agree2: newAgree,
      };
    }

    default: {
      //  console.log(`Unknown action ${action.type}`);
      return {
        ...state,
      };
    }
  }
};
export const SetValidAdress= (valid_adress, dispatch) => {
  if (dispatch({ type: SET_VALID_ADRESS, payload: valid_adress })) {
    return true;
  }
};
export const SetPlaceId= (place_id, dispatch) => {
  if (dispatch({ type: SET_PLACE_ID, payload: place_id })) {
    return true;
  }
};
export const SetRange = (range, dispatch) => {
  if (dispatch({ type: SET_RANGE, payload: range })) {
    return true;
  }
};
export const AddObligatoryAdditions = (obligatoryAdditionsForComent, dispatch) => {
  if (dispatch({ type: ADD_OBLIGATORY_ADDITIONS, payload: obligatoryAdditionsForComent })) {
    return true;
  }
};
export const RemoveObligatoryAdditions = (obligatoryAdditionsForComent, dispatch) => {
  if (dispatch({ type: REMOVE_OBLIGATORY_ADDITIONS, payload: obligatoryAdditionsForComent })) {
    return true;
  }
};
export const SetDeviceID = (deviceID, dispatch) => {
  if (dispatch({ type: SET_DEVICE_ID, payload: deviceID })) {
    return true;
  }
};
export const SetLastCity = (lastCity, dispatch) => {
  if (dispatch({ type: SET_LAST_CITY, payload: lastCity })) {
    return true;
  }
};
export const SetLastStreet = (lastStreet, dispatch) => {
  if (dispatch({ type: SET_LAST_STREET, payload: lastStreet })) {
    return true;
  }
};
export const SetLastBuildNr = (lastBuildNr, dispatch) => {
  if (dispatch({ type: SET_LAST_BUILDNR, payload: lastBuildNr })) {
    return true;
  }
};
export const SetaAtLocation = (atLocation, dispatch) => {
  if (dispatch({ type: SET_AT_LOCATION, payload: atLocation })) {
    return true;
  }
};

export const SetOrderByMyself = (order_by_myself, dispatch) => {
  if (dispatch({ type: SET_ORDER_BY_MYSELF, payload: order_by_myself })) {
    return true;
  }
};

export const AddOrderPosition = (order, dispatch) => {
  if (dispatch({ type: ADD_ORDER_POSITION, payload: order })) {
    return true;
  }
};
export const RemoveOrderPosition = (order, dispatch) => {
  if (dispatch({ type: REMOVE_ORDER_POSITION, payload: order })) {
    return true;
  }
};
export const AddToAmmount = (ammount, dispatch) => {
  if (dispatch({ type: ADD_TO_AMMOUNT, payload: ammount })) {
    return true;
  }
};
export const RemoveFromAmmount = (ammount, dispatch) => {
  if (dispatch({ type: REMOVE_FROM_AMMOUNT, payload: ammount })) {
    return true;
  }
};
export const SetDeliveryAmmount = (deliveryAmmount, dispatch) => {
  if (dispatch({ type: SET_DELIVERYAMMOUNT, payload: deliveryAmmount })) {
    return true;
  }
};
export const SetFreeAbove = (freeAbove, dispatch) => {
  if (dispatch({ type: SET_FREEABOVE, payload: freeAbove })) {
    return true;
  }
};
export const SetOff = (off, dispatch) => {
  if (dispatch({ type: SET_OFF, payload: off })) {
    return true;
  }
};
//Inputs validation
export const SetCity = (city, dispatch) => {
  if (dispatch({ type: SET_CITY, payload: city })) {
    return true;
  }
};
export const SetCityAPI = (city, dispatch) => {
  if (dispatch({ type: SET_CITY_API, payload: city })) {
    return true;
  }
};
export const setDelivery = (delivery, dispatch) => {
  if (dispatch({ type: SET_DELIVERY, payload: delivery })) {
    return true;
  }
};
export const SetStreet = (street, dispatch) => {
  if (dispatch({ type: SET_STREET, payload: street })) {
    return true;
  }
};
export const SetBuildNr = (buildNr, dispatch) => {
  if (dispatch({ type: SET_BUILDNR, payload: buildNr })) {
    return true;
  }
};
export const SetName = (name, dispatch) => {
  if (dispatch({ type: SET_NAME, payload: name })) {
    return true;
  }
};
export const SetSurame = (surname, dispatch) => {
  if (dispatch({ type: SET_SURNAME, payload: surname })) {
    return true;
  }
};
export const SetPhone = (phone, dispatch) => {
  if (dispatch({ type: SET_PHONE, payload: phone })) {
    return true;
  }
};
export const SetEmail = (email, dispatch) => {
  if (dispatch({ type: SET_EMAIL, payload: email })) {
    return true;
  }
};
export const SetCode = (code, dispatch) => {
  if (dispatch({ type: SET_CODE, payload: code })) {
    return true;
  }
};
export const SetComent = (coment, dispatch) => {
  if (dispatch({ type: SET_COMENT, payload: coment })) {
    return true;
  }
};
export const SetAgree = (agree, dispatch) => {
  if (dispatch({ type: SET_AGREE, payload: agree })) {
    return true;
  }
};
export const SetAgree2 = (agree2, dispatch) => {
  if (dispatch({ type: SET_AGREE2, payload: agree2 })) {
    return true;
  }
};
