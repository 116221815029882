import React from "react";

export default function CheckBoxComponent({
  options,
  add,
  remove,
  color,
  order_currency,
}) {
  const echo = (e, element) => {
    if (e.target.checked) {
        //console.log("true");
        //console.log(element);
      add(element);
    } else {
       //console.log("fasle");
        //console.log(element);
      remove(element);
    }
  };
  return (
    <>
      {options.map((element) => (
        <div
          key={element.id}
          className="cont  mr-md-5 mr-3 mt-3 Lite position-relative "
          style={{
            cursor: "pointer",
            userSelect: "none",
          }}
        >
          <input
            className="form-check-input d-none"
            type="checkbox"
            id={element.id}
            onChange={(e) => {
              echo(e, element);
            }}
            style={{
              cursor: "pointer",
            }}
          />

          <label
            className="form-check-label res"
            htmlFor={element.id}
            style={{
              cursor: "pointer",
            }}
          ></label>

          <label
            className="form-check-label res22"
            htmlFor={element.id}
            style={{
              cursor: "pointer",
            }}
          >
            {element.name} ({element.price.toFixed(2)} {order_currency})
          </label>
        </div>
      ))}
      <style>
        {`
        .cont input:checked + .res {
          border: 2px solid ${color};
        }
        .cont input:checked + .res:after {
          content: "";
          position: absolute;
          height: 7px;
          width: 10px;
          border-bottom: 2px solid ${color};
          border-left: 2px solid ${color};
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        
        `}
      </style>
    </>
  );
}
