import React, { useState, useEffect } from "react";
import IconsMineDisplay from "./IconsMineDisplay";
import { useSelector, useDispatch } from "react-redux";
import Category from "./Category";
import CheckBoxComponent from "./CheckBoxComponent";
import CheckBoxForOligatoryComponent from "./CheckBoxForOligatoryComponent";
import "../CSS/ItemWithOptions.css";
import "../CSS/Footer.css";
import Exit from "../Media/exit.svg";
import round from "../Functions/round";
import Footer from "./Footer";

import {
  AddOrderPosition, AddToAmmount, SetDeliveryAmmount, SetFreeAbove,
  SetCity,
  SetStreet,
  SetBuildNr,
  SetName,
  SetSurame,
  SetPhone,
  SetEmail,
  SetDeviceID,
  AddObligatoryAdditions,
} from "../store/OrderReducer";
import { SetAlert, SetIsApp, SetIsQr } from "../store/UserReducer";
import { Spring } from "react-spring/renderprops";
import TakeInfo from "./TakeInfo";
import SubmitDisplay from "./SubmitDisplay";
import { withRouter } from "react-router-dom";
import axios from "axios";
function MineDisplay({ test, history, match }) {
  const dispatch = useDispatch();
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const URL = useSelector((e) => e.UserReducer.url);
  const ValidAdress = useSelector((e) => e.OrderReducer.valid_adress);
  const OrderByMyself = useSelector((e) => e.OrderReducer.order_by_myself);
  const PlaceID = useSelector((e) => e.OrderReducer.place_id);
  const [Accept_regulations_error, setAccept_regulations_error] = useState("");
  const [Additions_font_color, setAdditions_font_color] = useState("");
  const [Additions_start_color, setAdditions_start_color] = useState("");
  const [Additions_stop_color, setAdditions_stop_color] = useState("");
  const [Correct_email_error, setCorrect_email_error] = useState("");
  const [ObligatoryHeaderText, setObligatoryHeaderText] = useState('')
  const [Correct_meal_error, setCorrect_meal_error] = useState("");
  const [Delivery_city, setDelivery_city] = useState("");
  const [Correct_phone_error, setCorrect_phone_error] = useState("");
  const [Correct_date_error, setCorrect_date_error] = useState("");
  const [Meal_additions_header, setMeal_additions_header] = useState("");
  const [Order_currency, setOrder_currency] = useState("");
  const Delivery = useSelector((a) => a.OrderReducer.delivery);
  const ObligatoryAditions = useSelector((a) => a.OrderReducer.obligatoryAdditionsForComent);
  const IS_APP = useSelector((a) => a.UserReducer.is_app);
  const IS_QR = useSelector((a) => a.UserReducer.is_qr);
  const IsApi = useSelector((a) => a.UserReducer.is_api);
  const IsFoodtruck = useSelector((e) => e.UserReducer.is_foodtruck);
  const FoodtruckAgree = useSelector((e) => e.UserReducer.foodtruck_location_agree);
  const [
    Confirm_meal_without_addition,
    setConfirm_meal_without_addition,
  ] = useState("");
  const [Confirm_meal_with_addition, setConfirm_meal_with_addition] = useState(
    ""
  );
  const [Added_to_order_communicate, setAdded_to_order_communicate] = useState(
    ""
  );
  //:app/:name/:surname/:cityID/:street/:buildingNR/:phoneNR/:email/:valid/:deviceID
  useEffect(() => {
    //alert(match.url)
    if (match.params.app === '2' && match.params.app !== '1') {
      if (SetIsApp(match.params.app, dispatch)) {
        SetDeviceID(match.params.deviceID, dispatch)
      }
    } else {
      if (match.params.qr === undefined) {
        history.push("/");
      }

    }
    if (match.params.app === '1' && match.params.valid === '1' && Delivery !== {} && match.params.app !== undefined && match.params.name !== undefined && match.params.surname !== undefined && match.params.cityID !== undefined && match.params.street !== undefined && match.params.buildingNR !== undefined && match.params.phoneNR !== undefined && match.params.email !== undefined && match.params.valid !== undefined) {
      if (SetIsApp(match.params.app, dispatch) && Delivery.places[match.params.cityID] !== undefined) {
        SetCity(Delivery.places[match.params.cityID], dispatch)
        SetStreet(match.params.street, dispatch)
        SetBuildNr(match.params.buildingNR.replace("^", "/"), dispatch)
        SetName(match.params.name, dispatch)
        SetSurame(match.params.surname, dispatch)
        SetPhone(match.params.phoneNR, dispatch)
        SetEmail(match.params.email, dispatch)
        SetDeviceID(match.params.deviceID, dispatch)
      }
    } else {
      if (match.params.qr === undefined && match.params.app !== '2') {
        history.push("/");
      }

    }
  }, [Delivery])
  useEffect(() => {
   // //console.log(match)
    if (Delivery !== {} && match.params.qr !== undefined && match.params.qr === '1' && match.params.ID !== undefined && match.params.ID !== '') {
      if (SetIsQr(match.params.qr, dispatch) && Delivery.places[match.params.ID] !== undefined) {
        SetCity(Delivery.places[match.params.ID], dispatch)
        SetStreet('-', dispatch)
        SetBuildNr('-', dispatch)
        SetName('-', dispatch)
        SetSurame('-', dispatch)
        if (match.params.phoneNR !== undefined && match.params.email !== undefined) {
          SetPhone(match.params.phoneNR, dispatch)
          SetEmail(match.params.email, dispatch)
        }
        if (match.params.deviceID !== undefined) {
          SetDeviceID(match.params.deviceID, dispatch)

        }

      }
    } else {
      if (match.params.app === undefined) {
        history.push("/");
      }
    }
  }, [Delivery])
  useEffect(() => {
    if (IsFoodtruck !== undefined && IsFoodtruck !== null) {
      if (IsFoodtruck && FoodtruckAgree === null && FoodtruckAgree !== true) {
        history.push("/informacja");
      }
    }
  }, [IsFoodtruck, FoodtruckAgree]);
  useEffect(() => {
    if (UserInfo.colors !== undefined) {
      setAdditions_font_color(UserInfo.colors.additions_font_color);
      setAdditions_start_color(UserInfo.colors.additions_start_color);
      setAdditions_stop_color(UserInfo.colors.additions_stop_color);
    }

    if (UserInfo.constant_texts !== undefined) {
      setAccept_regulations_error(
        UserInfo.constant_texts.accept_regulations_error
      );
      setObligatoryHeaderText(UserInfo.constant_texts.obligatory_header_text)
      setCorrect_email_error(UserInfo.constant_texts.correct_email_error);
      setCorrect_meal_error(UserInfo.constant_texts.correct_meal_error);
      setDelivery_city(UserInfo.constant_texts.delivery_city);
      setCorrect_phone_error(UserInfo.constant_texts.correct_phone_error);
      setCorrect_date_error(UserInfo.constant_texts.correct_data_error);
      setOrder_currency(UserInfo.constant_texts.order_currency);
      setConfirm_meal_without_addition(
        UserInfo.constant_texts.confirm_meal_without_addition
      );
      setButton_text(UserInfo.constant_texts.confirm_meal_without_addition)
      setConfirm_meal_with_addition(
        UserInfo.constant_texts.confirm_meal_with_addition
      );
      setAdded_to_order_communicate(
        UserInfo.constant_texts.added_to_order_communicate
      );
    }
    if (UserInfo.editable_texts !== undefined) {
      setMeal_additions_header(UserInfo.editable_texts.meal_additions_header);
    }
  }, [UserInfo]);
  const [Height, setHeight] = useState(
    window.scrollY >= 0 && window.scrollY <= 150 && window.innerWidth >= 1400
      ? 200
      : -0.75 * window.scrollY + 200
  );

  useEffect(() => {
    window.addEventListener("scroll", function (e) {
      if (
        window.scrollY >= 0 &&
        window.scrollY <= 150 &&
        window.innerWidth >= 1400
      ) {
        setHeight(-0.75 * window.scrollY + 200);
      } else {
        setHeight(91.55);
      }
    });
  });
  const Categorier = useSelector((a) => a.MenuReducer.menu);
  const [Addition, setAddition] = useState([]);
  const [Display, setDisplay] = useState("Mine");
  const [DisplayConfirm, setDisplayConfirm] = useState("hidden");
  const [AdditionalList, setAdditionalList] = useState({});
  const [Meal, setMeal] = useState([]);
  const [LocalAmount, setLocalAmount] = useState(0);
  const [Button_text, setButton_text] = useState(Confirm_meal_without_addition);
  const addCheckedElement = (element) => {
    let list = AdditionalList;
    setAdditionalList();
    list[element.id] = {
      id: element.id,
      name: element.name,
      price: element.price,
      quantity: 1
    };
    setAdditionalList(list);
    if (Object.keys(list).length > 0) {
      setButton_text(Confirm_meal_with_addition);
    } else if (Object.keys(list).length === 0) {
      setButton_text(Confirm_meal_without_addition);
    }
    setLocalAmount(0.0);
    setLocalAmount(LocalAmount + element.price);
    //// //console.log("lista dodatków");
    ////console.log(list);
    // //console.log(AdditionalList);
  };

  const removeCheckedElement = (element) => {
    let list = AdditionalList;
    delete list[element.id];
    setAdditionalList(list);
    if (Object.keys(list).length > 0) {
      setButton_text(Confirm_meal_with_addition);
    } else if (Object.keys(list).length === 0) {
      setButton_text(Confirm_meal_without_addition);
    }
    setLocalAmount(0.0);
    setLocalAmount(LocalAmount - element.price);
    //// //console.log("lista dodatków");
    //// //console.log(list);
  };
  const [Change, setChange] = useState(0)
  const [Lock, setLock] = useState(false)
  const [SellectedObligatoryAdditions, setSellectedObligatoryAdditions] = useState({})

  const addObligatoryAddition = (element) => {
    let obligatoryAdditions = SellectedObligatoryAdditions
    obligatoryAdditions[element.id + 'Marker'] = {
      id: element.id,
      name: element.name
    }
    ////console.log(obligatoryAdditions)
    if (Change + 1 >= Max) {
      setLock(true)
    } else {
      setLock(false)
    }
    setChange(Change + 1)
    setSellectedObligatoryAdditions(obligatoryAdditions)
    SetAlert(null, dispatch)
  }
  const removeObligatoryAddition = (element) => {
    let obligatoryAdditions = SellectedObligatoryAdditions
    delete obligatoryAdditions[element.id + 'Marker'];
    ////console.log(obligatoryAdditions)
    if (Change - 1 >= Max) {
      setLock(true)
    } else {
      setLock(false)
    }
    setChange(Change - 1)
    setSellectedObligatoryAdditions(obligatoryAdditions)
    SetAlert(null, dispatch)
  }
  const [ScrollY, setScrollY] = useState(null)
  const [Additions, setAdditions] = useState('none')
  const [ObligatoryAdditions, setObligatoryAdditions] = useState([])
  const [BeseMealName, setBeseMealName] = useState('')
  const [OpttionName, setOpttionName] = useState('')
  const [Min, setMin] = useState(0)
  const [Max, setMax] = useState(0)
  const showAdditions = (order, addition, ingredients_list, min, max) => {
    ////console.log(ingredients_list)
    setBeseMealName(order.name)
    setMin(min)
    setMax(max)
    setOpttionName(order.optName)
    setDisplayConfirm("hidden");
    setMeal(order);
    if (addition !== undefined) {
      if (addition.length > 0) {
        setAddition(addition);
        setAdditions('block')
      }
    }
    if (ingredients_list !== undefined) {
      if (ingredients_list.length > 0) {
        setObligatoryAdditions(ingredients_list);
        setAdditions('block')
      }
    }
    setLocalAmount(order.price);
    setScrollY(window.pageYOffset)
  };
  const cancel = () => {
    window.scrollTo({ top: ScrollY });
    setAdditionalList({});
    setLocalAmount(0);
    setAddition([]);
    setObligatoryAdditions([]);
    setAdditions('none')
    setSellectedObligatoryAdditions({})
    setBeseMealName('')
    setOpttionName('')
    setMax(0)
    setMin(0)
    setLock(false)
    setChange(0)
    SetAlert(null, dispatch)
  };

  const addItemToOrder = () => {
    SetAlert(null, dispatch)
    let d = new Date();
    let n = d.getTime();
    ////console.log((Object.keys(ObligatoryAdditions).length > 0 && Object.keys(SellectedObligatoryAdditions).length >= Min && Object.keys(SellectedObligatoryAdditions).length <= Max) || Object.keys(ObligatoryAdditions).length == 0)
    if ((Object.keys(ObligatoryAdditions).length > 0 && Object.keys(SellectedObligatoryAdditions).length >= Min && Object.keys(SellectedObligatoryAdditions).length <= Max) || Object.keys(ObligatoryAdditions).length == 0) {



      if (Object.keys(SellectedObligatoryAdditions).length > 0) {
        let obligatoryadd = [BeseMealName, OpttionName, SellectedObligatoryAdditions, n];
        AddObligatoryAdditions(obligatoryadd, dispatch)
      }
      ////console.log(n)

      let OrderItem = [Meal, AdditionalList, LocalAmount, n];
      if (
        AddOrderPosition(OrderItem, dispatch) &&
        AddToAmmount(OrderItem[2], dispatch)
      ) {
        ////console.log("dodany item");
        ////console.log(OrderItem);

        setDisplayConfirm("visible");
        setTimeout(function () {
          cancel();
        }, 1000);
      }
    } else {
      if (Max === Min) {
        SetAlert(`Wybierz ${Min} z listy`, dispatch)
      }
      else {
        SetAlert(`Wybierz od ${Min} do ${Max} z listy`, dispatch)
      }
    }


  };
  const setOpenClose = async () => {
    let isopen = null
    await axios
      .get(`${URL}/get_open_close.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        // // //console.log(response.data.is_open.auto_open_close);

        if (response.data.is_open.auto_open_close) {
          let d = new Date();
          let n = d.getDay();

          let h = timeToDecimal(
            d.getHours().toString() + ":" + d.getMinutes().toString()
          );
          //// //console.log("--------------------------");

          if (
            response.data.opening_hour[n] !== "-" &&
            response.data.closing_hour[n] !== "-"
          ) {
            // // //console.log(n);
            let min = timeToDecimal(response.data.opening_hour[n]);
            //// //console.log(min);
            let max = timeToDecimal(response.data.closing_hour[n]);
            //  // //console.log(max);
            if (h > min && h < max) {
              //  SetIsOpen(true, dispatch);
              isopen = true;
              return isopen;
            } else {
              //  SetIsOpen(false, dispatch);
              isopen = false;
              return isopen;
            }
          } else {
            isopen = false;
            return isopen;
          }
        } else {
          //  SetIsOpen(response.data.is_open.is_open, dispatch);
          //  SetMode(response.data.is_open.auto_open_close, dispatch);
          // //console.log(response.data.is_open.is_open);
          isopen = response.data.is_open.is_open;
          return isopen;
          // sprwadzaj czy ustawia i jesli true to apka załadowana
        }

        // SetMode(response.data.is_open.auto_open_close, dispatch)
        //   SetIsOpen(response.data.is_open.is_open, dispatch)
      })

      .catch((err) => {
        // //console.log(err.response);
        isopen = null;
        return isopen;
      });

    return isopen;


  }
  const timeToDecimal = (t) => {
    t = t.split(":");
    return parseFloat(parseInt(t[0], 10) + parseInt(t[1], 10) / 60);
  };
  const addItemToOrderNoAdditions = (order, addition) => {
    let now = new Date();
    let n = now.getTime()
    let OrderItem = [order, addition, order.price, n];
    if (
      AddOrderPosition(OrderItem, dispatch) &&
      AddToAmmount(OrderItem[2], dispatch)
    ) {
      return true;
    }
  };
  //-------------------------Tworzenie ORDERU---------------------------------
  const dataCrator = () => {
    let now = new Date();

    return `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
  };
  const timeCrator = () => {
    let now = new Date();
    let s = `${now.getMinutes()}`;
    // //console.log(s);
    if (s.length == 1) {
      s = "0" + `${now.getMinutes()}`;
    }
    return `${now.getHours()}:${s}`;
  };
  const random = () => {
    let cod =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    //////console.log("Wyrandowany kod:" + cod);
    return cod;
  };
  const optionsCreator = (add) => {
    let additions = [];
    Object.keys(add).map((element) => {
      additions.push({
        addition: add[element].id,
        quantity: 1
      });
    });
    //// //console.log(additions);
    return additions;
  };
  const mealCreator = () => {
    //// //console.log(Order);
    let meals = [];
    Object.keys(Order).map((element) => {
      meals.push({
        meal_option: Order[element].idOption,
        quantity: 1,
        additions: optionsCreator(Order[element].additions),
      });
    });
    //// //console.log(meals);
    return meals;
  };

  const [Description, setDescription] = useState(null);

  const Agree = useSelector((a) => a.OrderReducer.agree);
  const Agree2 = useSelector((a) => a.OrderReducer.agree2);
  const Order = useSelector((a) => a.OrderReducer.order);
  const City = useSelector((a) => a.OrderReducer.city);
  const CityAPI = useSelector((a) => a.OrderReducer.cityAPI);
  const Range = useSelector((a) => a.OrderReducer.range);
  const Street = useSelector((a) => a.OrderReducer.street);
  const BuildNr = useSelector((a) => a.OrderReducer.buildNr);
  const Name = useSelector((a) => a.OrderReducer.name);
  const Surname = useSelector((a) => a.OrderReducer.surname);
  const Phone = useSelector((a) => a.OrderReducer.phone);
  const Email = useSelector((a) => a.OrderReducer.email);
  const Ammount = useSelector((a) => a.OrderReducer.ammount);
  const FreeAbove = useSelector((e) => e.OrderReducer.freeAbove);
  const DeliveryAmmount = useSelector((a) => a.OrderReducer.deliveryAmmount);
  const Code = useSelector((a) => a.OrderReducer.code);
  const Coment = useSelector((a) => a.OrderReducer.coment);
  const Off = useSelector((a) => a.OrderReducer.off);
  const DeviceID = useSelector((a) => a.OrderReducer.deviceID);
  const [Json, setJson] = useState(null);
  const IsOpen = useSelector((e) => e.UserReducer.isOpen);
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  // const checkDistanceDeliveryAmmount = (distance) => {
  //   let free_above = null
  //   let delivery_price = null
  //   let currentRange = 9999999999999999
  //   ////console.log(distance)
  //   ////console.log(Delivery.ranges)
  //   Object.keys(Delivery.ranges).map((key) => {
  //     if (distance <= Delivery.ranges[key].max_distance && Delivery.ranges[key].max_distance < currentRange) {
  //       currentRange = Delivery.ranges[key].max_distance
  //       free_above = Delivery.ranges[key].free_above
  //       delivery_price = Delivery.ranges[key].delivery_price
  //     }
  //   });
  //   let json = {
  //     free_above: free_above,
  //     delivery_price: delivery_price
  //   }
  //   return json;
  // }

  const GoogleAPI = async (is_api) => {

    let check = await setOpenClose()
    if (check === true) {

      if (
        Object.keys(Order).length !== 0 &&
        (City !== null || CityAPI !== null || (ValidAdress!=='' && is_api)) &&
        IsOpen === true &&
        (Street.length > 0 || (ValidAdress!=='' && is_api))  &&
        BuildNr.length > 0 &&
        Name.length > 0 &&
        Surname.length > 0 &&
        Phone.length === 9 &&
        Email.length > 0 &&
        Agree === true
      ) {

        if (validateEmail(Email)) {

          if (!OrderByMyself) {

            ////console.log(IS_APP !== '1' && IS_APP !== '2' && IS_QR !== '1')
            if (IS_APP === null && IS_APP !== '1' && IS_APP !== '2' && IS_QR !== '1') {

              if (is_api) 
              { // api albo klasyczne PlaceID

                localStorage.setItem('PlaceID', PlaceID)
                localStorage.setItem('BuildNr', BuildNr)
                localStorage.setItem('Name', Name)
                localStorage.setItem('Surname', Surname)
                localStorage.setItem('Phone', Phone)
                localStorage.setItem('Email', Email)
                //console.log(PlaceID)
                createOrder(Range.value,DeliveryAmmount, FreeAbove, true);// api albo klaszynie
              } else {
                localStorage.setItem('City', City.id)
                localStorage.setItem('Street', Street)
                localStorage.setItem('BuildNr', BuildNr)
                localStorage.setItem('Name', Name)
                localStorage.setItem('Surname', Surname)
                localStorage.setItem('Phone', Phone)
                localStorage.setItem('Email', Email)
                localStorage.setItem('Verified', false)
                localStorage.setItem('Range', null)
                createOrder(0, null, null, false);// api albo klaszynie
              }

            } else if (IS_APP === '1' || IS_APP === '2') {
              createOrder(0, null, null, false);// api albo klaszynie
            } else if (IS_QR === '1') {
              localStorage.setItem('Phone', Phone)
              localStorage.setItem('Email', Email)
              createOrder(0, null, null, false);// api albo klaszynie
            }
          } else {
            localStorage.setItem('Name', Name)
            localStorage.setItem('Surname', Surname)
            localStorage.setItem('Phone', Phone)
            localStorage.setItem('Email', Email)
            createOrder(0, null, null, false);// api albo klaszynie
          }

        } else {
          SetAlert(Correct_email_error, dispatch);
        }
      } else {
        if (Object.keys(Order).length === 0) {
          SetAlert(Correct_meal_error, dispatch);
        } else if (City === null && CityAPI === null && ValidAdress==='') { // api albo klasyczny
          //console.log(City,CityAPI)
          SetAlert(Delivery_city, dispatch);
        } else if (Phone.length !== 9) {
          SetAlert(Correct_phone_error, dispatch);
        } else if (Agree !== true) {
          SetAlert("Zaakceptuj regulamin", dispatch);
        } else if (Email === "") {
          SetAlert(Correct_email_error, dispatch);
        } else {
          SetAlert(Correct_date_error, dispatch);
        }
      }
    } else {
      window.location.reload(false);
    }
  }
  const makeText = (object) => {
    let text = " ("
    Object.keys(object).map((key) => {
      text = text + object[key].name + ', '
    })
    text = text.substring(0, text.length - 2);
    return text + ") "
  }
  const createComent = (coment, obligatory) => {
    ////console.log(obligatory)
    let text = ""
    if (Object.keys(obligatory).length > 0) {
      Object.keys(obligatory).map((key) => {
       // //console.log(key)
        let obligatoryElementList = makeText(obligatory[key].obligatoryAdditions)
        text = text + obligatory[key].name + obligatory[key].optName + obligatoryElementList
      })
      text = "Wybrane dodatki: " + text + "Komentarz: " + coment
    } else {
      text = coment
    }
    ////console.log(text)
    return text
  }
  const createOrder = async (distance, deliveryAmmount, freeAbove, is_api) => {

    let IntDistance = parseInt(distance)
    //console.log(is_api,Ammount,Off,FreeAbove,DeliveryAmmount)
    let price = is_api === false ?
      Ammount - Off < FreeAbove
        ? round(Ammount + City.delivery_price - Off)
        : round(Ammount - Off) : FreeAbove!==null?
      Ammount - Off < FreeAbove
        ? round(Ammount + DeliveryAmmount - Off)
        : round(Ammount - Off)
        : round(Ammount + DeliveryAmmount - Off)



    let control = await random();
    let description =
      Name + " " + Surname + " tel:" + Phone + " mail:" + Email;
    setDescription(description);
    setJson({
      delivery_city: IsApi || OrderByMyself? null: City.id,
      client_name: Name + " " + Surname,
      delivery_address: OrderByMyself? 'Odbiór osobisty' :IsApi? ValidAdress==='' ? CityAPI+ ' ' +Street + " " + BuildNr: ValidAdress : Street + " " + BuildNr,
      phone_number: Phone,
      email: Email,
      ordered_date: await dataCrator(),
      ordered_time: await timeCrator(),
      to_pay: price.toFixed(2),
      delivery_price: is_api === false ? Ammount - Off < FreeAbove ? DeliveryAmmount : 0 : Ammount - Off < freeAbove ? deliveryAmmount : DeliveryAmmount,
      comment: await createComent(Coment, ObligatoryAditions),
      preparation_time: "",
      promotion_code: Code,
      payment_control: control,
      payment_type: "",
      payment_status: "",
      progress: "",
      meal_options: await mealCreator(),
      marketing_permit: Agree2,
      distance: IsApi? IntDistance:null,
      device_id: DeviceID,
      is_internal: IS_QR === null ? false : IS_QR
    });
    setDisplay("Submit");
    SetAlert(null, dispatch);
    window.scrollTo(0, 0);


  };
  if (Display === "Mine") {
    return (
      <>
        <div
          className="container"
          style={{
            display: Additions,
            height: '100%',
            background: '#ffffff',
            position: 'fixed',
            zIndex: 1000,
            overflowY: 'auto'
          }}>
          <div
            className="row px-md-5 px-1 pt-md-4 pt-0"
            style={{ paddingBottom: "450px" }}
          >
            <div className="col-10 px-0 my-1 my-md-0">

            </div>
            <div className="col-2 px-0 my-1 my-md-0">
              <img
                onClick={cancel}
                src={Exit}
                alt="Anuluj"
                id="exit"
                className="d-block mx-auto my-sm-3"
              />
            </div>
            {ObligatoryAdditions.length > 0 ?
              <>

                <div className="col-12 px-0 my-4 my-md-0">
                  <p className="h2 Bold upper my-sm-3 footer-font2">
                    {ObligatoryHeaderText}
                  </p>
                </div>
                <div className="col-12 px-0 mb-2 mb-md-4 f-size-disc">
                  <CheckBoxForOligatoryComponent
                    options={ObligatoryAdditions}
                    add={addObligatoryAddition}
                    remove={removeObligatoryAddition}
                    color={Additions_stop_color}
                    order_currency={Order_currency}
                    lock={Lock}
                  />
                </div>
              </> : ''
            }
            {
              Addition.length ? <div className="col-10 px-0 my-4 my-md-0">
                <p className="h2 Bold upper my-sm-3 footer-font2">
                  {Meal_additions_header}
                </p>
              </div> : ''
            }

            <div className="col-2 px-0 my-3 my-md-0">

            </div>


            {Addition.map((element) => (
              <>
                <div className="col-12 px-0 mt-md-3  mb-1">
                  <p className="h3 Bold upper f-size-title mb-0">
                    {element.name}
                  </p>
                </div>

                <div className="col-12 px-0 mb-4 mb-md-2 mb-2 f-size-disc">
                  <CheckBoxComponent
                    options={element.additions}
                    add={addCheckedElement}
                    remove={removeCheckedElement}
                    color={Additions_stop_color}
                    order_currency={Order_currency}
                  />
                </div>
              </>
            ))}
          </div>
          <div
            className="container-fluid chosenMealArea px-md-5 pt-0"
            style={{ display: Display }}
          >
            <div
              className="container  px-0 py-0 "
              style={{
                background: `transparent linear-gradient(180deg, ${Additions_start_color} 0%, ${Additions_stop_color} 100%) 0% 0% no-repeat padding-box`,
                color: `${Additions_font_color}`,
              }}
            >
              <div className="row ">
                <div className="col-12 px-md-5 px-3 ">
                  <div className="row py-lg-3 py-md-0">
                    <div className="col-lg-7 mb-md-0 mb-2 px-0 col-sm-10 col-9 d-flex align-items-center pt-2 pt-md-0 positon-relative">
                      <span>
                        <p className="f-size-title Bold upper mb-0">
                          {Meal.name}{" "}
                          {Meal.optName === "" ? "" : "- " + Meal.optName}
                        </p>
                        <p className="mb-1">
                          {
                            Object.keys(SellectedObligatoryAdditions).map((key) => (
                              <span className="mr-1">
                                {SellectedObligatoryAdditions[key].name}
                              </span>
                            ))
                          }

                        </p>
                        <p className="f-size-disc mb-2">
                          {
                            Object.keys(AdditionalList).map((key) => (
                              <span className="mr-1">
                                {AdditionalList[key].name}
                              </span>
                            ))
                          }
                        </p>
                      </span>
                    </div>
                    <div className="col-sm-2 col-3 mb-md-0 mb-2 px-0 text-center d-flex align-items-center justify-content-end">
                      {" "}
                      <p className="upper Bold mb-0 f-size-title">
                        {round(LocalAmount).toFixed(2)} {Order_currency}
                      </p>
                    </div>
                    <div className="col-lg-3  px-0 text-center d-flex align-items justify-content-end py-md-3 pb-2 ">
                      <button
                        className="btn add-regular-additions Bold  py-2 upper"
                        onClick={() => {
                          addItemToOrder();
                        }}

                        style={{
                          background: `white`,
                          color: `${Additions_stop_color}`,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {Button_text}
                      </button>
                    </div>
                  </div>

                  <div
                    className={
                      "confirmation3 text-center    d-flex justify-content-center align-items-center " +
                      DisplayConfirm
                    }
                  >
                    <p className="mb-0 Bold text-white h3 h3-custom">
                      {" "}
                      {Added_to_order_communicate}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {Categorier !== [] && Categorier !== null && Categorier !== undefined ? Categorier.map((element) => {
          if (element.is_active) {
            return (
              <Category
                key={element.id}
                category={element}
                additions={showAdditions}
                addNoAdditions={addItemToOrderNoAdditions}
              />
            );
          }
        }) : ''}
        <IconsMineDisplay />
        <TakeInfo func={GoogleAPI} />

        <Footer />

      </>
    );
  } else if (Display === "Submit") {
    return (
      <SubmitDisplay
        name={Name}
        surname={Surname}
        json={Json}
        description={Description}
        additions_font_color={Additions_font_color}
        additions_start_color={Additions_start_color}
        additions_stop_color={Additions_stop_color}
        height={test}
        set={setDisplay}
      />
    );
  }
}
export default withRouter(MineDisplay);