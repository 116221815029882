import React, { useState, useEffect } from "react";
import "../CSS/TakeInfo.css";
import { useSelector, useDispatch } from "react-redux";
import hexatorgb from "../Functions/hexatorgb";
import { Form,Button } from "react-bootstrap";
import { SetAlert } from "../store/UserReducer";
import axios from "axios";
import { Spinner } from 'react-bootstrap'
import HeaderIconSelector from "./HeaderIconSelector";
import Edit from "../Media/edit.svg"
import {
  SetPlaceId,
  SetCityAPI,
  SetCity,
  SetStreet,
  SetBuildNr,
  SetName,
  SetSurame,
  SetPhone,
  SetEmail,
  SetDeliveryAmmount,
  SetFreeAbove,
  SetRange,
  SetOrderByMyself,
  SetLastCity,
  SetLastStreet,
  SetLastBuildNr,
  SetValidAdress
} from "../store/OrderReducer";
export default function ClientInfo({
  color,
  email,
  street,
  bulidingNr,
  name,
  surname,
  phone,
}) {
  const dispatch = useDispatch();

  const URL = useSelector((e) => e.UserReducer.url);
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const Delivery = useSelector((a) => a.OrderReducer.delivery);
  const Street = useSelector((a) => a.OrderReducer.street);
  const BuildNr = useSelector((a) => a.OrderReducer.buildNr);
  const Name = useSelector((a) => a.OrderReducer.name);
  const IsAPi = useSelector((a) => a.UserReducer.is_api);
  const Surname = useSelector((a) => a.OrderReducer.surname);
  const Phone = useSelector((a) => a.OrderReducer.phone);
  const Email = useSelector((a) => a.OrderReducer.email);
  const City = useSelector((a) => a.OrderReducer.city);
  const CityAPI = useSelector((a) => a.OrderReducer.cityAPI);
  const OffRedux = useSelector((e) => e.OrderReducer.off);
  const Ammount = useSelector((e) => e.OrderReducer.ammount);
  const FreeAbove = useSelector((e) => e.OrderReducer.freeAbove);
  const OrderByMyself = useSelector((e) => e.OrderReducer.order_by_myself);
  const AtLocation = useSelector((a) => a.OrderReducer.atLocation);
  const DeliveryAmmount = useSelector((a) => a.OrderReducer.deliveryAmmount);
  const LastCity = useSelector((a) => a.OrderReducer.lastCity);
  const LastStreet = useSelector((a) => a.OrderReducer.lastStreet);
  const LastBuildNr = useSelector((a) => a.OrderReducer.lastBuildNr);
  const IsFoodtruck = useSelector((e) => e.UserReducer.is_foodtruck);
  const [DeliveryCostIncluded, setDeliveryCostIncluded] = useState("");
  const [PlaceIDAdress, setPlaceIDAdress] = useState(null);
  const [DeliveryRegionPrice, setDeliveryRegionPrice] = useState("");
  const [Color, setColor] = useState("");
  const [Delivery_city, setDelivery_city] = useState("");
  const [Delivery_client_contact, setDelivery_client_contact] = useState("");
  const [Delivery_address_header, setDelivery_address_header] = useState("");
  const [Order_currency, setOrder_currency] = useState("");
  const [Delivery_is_free, setDelivery_is_free] = useState("");
  const [placesArray, setPlacesArray] = useState({ id: null, label: "", small_label: "" })
  const [loading, setloading] = useState(false)
  const IS_QR = useSelector((a) => a.UserReducer.is_qr);
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmAdress, setConfirmAdress] = useState(false)
  const [PlaceID, setPlaceID] = useState('')
  const [LoadingFinalPrice, setLoadingFinalPrice] = useState(false)
  useEffect(() => {
    if (UserInfo.colors !== undefined) {
      setColor(hexatorgb(UserInfo.colors.additions_font_color, 15));
    }
    if (UserInfo.editable_texts !== undefined) {
      setDelivery_client_contact(
        UserInfo.editable_texts.delivery_client_contact
      );
      setDelivery_address_header(
        UserInfo.editable_texts.delivery_address_header
      );
      setPlaceID(UserInfo.editable_texts.google_maps_place_ID);
    }
    if (UserInfo.constant_texts !== undefined) {
      setDeliveryCostIncluded(UserInfo.constant_texts.delivery_cost_included);
      setDeliveryRegionPrice(UserInfo.constant_texts.delivery_region_price);
      setDelivery_city(UserInfo.constant_texts.delivery_city);
      setOrder_currency(UserInfo.constant_texts.order_currency);
      setDelivery_is_free(UserInfo.constant_texts.delivery_is_free);
    }
  }, [UserInfo]);
  useEffect(() => {
    if (City !== null && City !== undefined)
      CityValid(City.id)
  }, [City]);

  const getPlaceFromStorage = async () => {
    let json = {
      restaurant_place_id: PlaceID,
      place_id: localStorage.getItem('PlaceID'),
    }
    await axios
      .post(
        `${URL}/get_distance.php`,
        json,
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        //console.log(response)
       
        if(setDeliveryPrices(response.data.rows[0].elements[0].distance)){
          setPlaceIDAdress(response.data.destination_addresses[0])
          SetValidAdress(response.data.destination_addresses[0],dispatch)
        }
      }).catch((error) => {
        //console.log(error)
      })
  }
  useEffect(() => {
    if (localStorage.getItem('PlaceID') !== null && localStorage.getItem('PlaceID') !== 'null' && localStorage.getItem('PlaceID') !== undefined && IsAPi) {
        SetPlaceId(localStorage.getItem('PlaceID'), dispatch)
        getPlaceFromStorage()
    }

  }, []);


  const setDeliveryPrices = (distance) => {
    //console.log(distance.value, Delivery)
    let range = null
    Object.keys(Delivery.ranges).map((key) => {
      if (distance.value <= Delivery.ranges[key].max_distance) {
        if (range !== null) {
          if (Delivery.ranges[key].max_distance < range.max_distance) {
            range = Delivery.ranges[key]
            //console.log(1,Delivery.ranges[key])
          }
        } else {
          range = Delivery.ranges[key]
          //console.log(2,Delivery.ranges[key])
        }
      }
    })
    let response = false
    if (range !== null) {
      SetDeliveryAmmount(range.delivery_price, dispatch);
      SetFreeAbove(range.free_above, dispatch);
      SetRange(distance, dispatch)
      response = true
    }else{
      SetAlert('Twój adres znajduje sie poza zasięgiem dostaw. Skontaktuj się zrestauracją', dispatch);
      SetCityAPI('', dispatch);
      SetStreet('', dispatch);
      setloading(false)
      setPlacesArray({ id: null, label: "" })
    }
    return response
  }
  const loadOptions = async (inputValue) => {
    if (inputValue.length < 4) {
      setPlacesArray({ id: null, label: "" })
      setloading(false)
      return
    }
    let json = {
      adress: CityAPI + ' ' + inputValue,
    }
    await axios
      .post(
        `${URL}/get_suggestions.php`,
        json,
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        //console.log(response);
        if (response.data.predictions !== undefined) {
          setPlacesArray({ id: response.data.predictions[0].place_id, label: response.data.predictions[0].structured_formatting.main_text, small_label: response.data.predictions[0].structured_formatting.secondary_text })
        }
      }).catch((err) => {
        //console.log(err)
      });
    setloading(false)
  };
  const CityValid = (value) => {
    //console.log(value);
    if (Delivery.places[value] !== undefined) {
      if (IsAPi) { //Api albo klasyczne
        SetCity(Delivery.places[value], dispatch);
        //console.log(Delivery.places[value])

      } else {
        SetCity(Delivery.places[value], dispatch);
        SetDeliveryAmmount(Delivery.places[value].delivery_price, dispatch);
        SetFreeAbove(Delivery.places[value].free_above, dispatch);
      }
    } else {

      SetCity(null, dispatch);
      SetDeliveryAmmount(0, dispatch);
      SetFreeAbove(null, dispatch);
    }
    //console.log(Delivery);
  };
  const ChangeTakeOrderType = (event) => {
    if (event.target.checked) {
      SetDeliveryAmmount(0, dispatch);
      SetFreeAbove(0, dispatch);
      SetOrderByMyself(true, dispatch);
      SetLastCity(City, dispatch)
      SetLastStreet(Street, dispatch)
      SetLastBuildNr(BuildNr, dispatch)
      //console.log('AtLocation',AtLocation)
      SetCity(AtLocation, dispatch)
      SetStreet('-', dispatch)
      SetBuildNr('-', dispatch)
    } else {
      SetCity(LastCity, dispatch)
      SetStreet(LastStreet, dispatch)
      SetBuildNr(LastBuildNr, dispatch)
      SetOrderByMyself(false, dispatch);
    }
  }

  const [timer, setTimer] = useState(setTimeout(() => { }, 1000))
  const StreetValid = (event) => {
    if (IsAPi) {
      setConfirmAdress(false)
      setErrorMessage('')
      clearTimeout(timer);
      SetStreet(event.target.value, dispatch);
      SetAlert(null, dispatch);
      const val = event.target.value
      setloading(true)
      setTimer(setTimeout(() => {
        loadOptions(val)
      }, 1100))
    } else {
      SetStreet(event.target.value, dispatch);
      SetAlert(null, dispatch);
    }

  };
  const CityAPIValid = (event) => {
    setConfirmAdress(false)
    SetCityAPI(event.target.value, dispatch);
    SetAlert(null, dispatch);
  };
  const BuildNrValid = (event) => {
    SetBuildNr(event.target.value, dispatch);
    SetAlert(null, dispatch);
  };
  const NameValid = (event) => {
    SetName(event.target.value, dispatch);
    SetAlert(null, dispatch);
  };
  const SurnameValid = (event) => {
    SetSurame(event.target.value, dispatch);
    SetAlert(null, dispatch);
  };
  const PhoneValid = (event) => {
    SetPhone(event.target.value, dispatch);
    SetAlert(null, dispatch);
  };
  const EmailValid = (event) => {
    SetEmail(event.target.value, dispatch);
    SetAlert(null, dispatch);
  };
  const containsNumber = (str) => {
    return /\d/.test(str);
  }

  const selectPleace = async (id) => {
    setLoadingFinalPrice(true)
    let json = {
      place_id: id,
    }
    await axios
      .post(
        `${URL}/valid_adress.php`,
        json,
        {
          "Content-Type": "application/json",
        }
      )
      .then(async (response) => {
        //console.log(response)
        if (response.data.results[0].address_components !== undefined) {
          const indexStreet = response.data.results[0].address_components.findIndex(e => e.types[0] === 'street_number')
          const indexRoute = response.data.results[0].address_components.findIndex(e => e.types[0] === 'route')
          const indexCity = response.data.results[0].address_components.findIndex(e => e.types[0] === 'locality')
          if (indexStreet >= 0 && indexRoute >= 0 && indexCity >= 0) {
            const newName = response.data.results[0].address_components[indexRoute].long_name + " " + response.data.results[0].address_components[indexStreet].long_name
            let json = {
               restaurant_place_id: PlaceID,
               place_id: placesArray.id,
            }
            await axios
              .post(
                `${URL}/get_distance.php`,
                json,
                {
                  "Content-Type": "application/json",
                }
              )
              .then((response2) => {
                if(setDeliveryPrices(response2.data.rows[0].elements[0].distance)){
                  setPlacesArray({ id: placesArray.id, label: newName, small_label: placesArray.small_label })
                  SetPlaceId(placesArray.id, dispatch)
                  SetStreet(newName, dispatch);
                  SetCityAPI(response.data.results[0].address_components[indexCity].long_name, dispatch)
                  setConfirmAdress(true)
                }
              }).catch((error) => {
                //console.log(error)
              })
 
          } else {
            setErrorMessage('Upeni się, że podany adres jest prawidłowy ')
            if (containsNumber(Street)) {
              setErrorMessage('Podany adres nie istnieje')
            } else {
              setErrorMessage('adres musi zawierć ulicę i numer budynku')
            }
          }
          ////console.log(response);
        }

      }).catch((err) => {
        //console.log(err)
      });
    setLoadingFinalPrice(false)
  }
  return (
    <div className={IS_QR ? 'col-12 px-md-5 px-4 pt-md-5 mt-md-0 mt-1 mb-3' : 'col-12 px-md-5 px-4 pt-md-5 pt-5 mt-md-0 mt-1 mb-3'}>
      <div className="row">
        <div className="col-md-6 px-0" style={{
          display: IS_QR ? 'none' : 'block'
        }}>

          <p className="Bold h2 upper Order-font1">{Delivery_address_header}</p>
          {
            OrderByMyself == false && IsFoodtruck !== true ? <>
              {
                IsAPi !== true ? <Form className="sellect">
                  <Form.Group controlId="exampleForm.SelectCustom">
                    <Form.Control
                      as="select"
                      custom
                      className="px-0"
                      style={{
                        background: "rgba(255,255,255,0)",
                        color: `${color}`,
                        border: "none",
                        borderRadius: "0px",
                        borderBottom: `1px solid ${Color}`,
                      }}
                      onChange={(e) => {
                        CityValid(e.target.value);
                      }}
                    >
                      <option
                        className="Lite"
                        value={0}
                        style={{
                          color: `#000000`,
                        }}
                      >
                        {City !== null && City !== undefined ? City.name : `${Delivery_city}`}
                      </option>
                      {Object.keys(Delivery?.places)?.map((element) => {
                        if (Delivery.places[element].is_place === true && Delivery.places[element].is_stationary !== true) {
                          return (
                            <option
                              className="Lite"
                              value={Delivery.places[element].id}
                              style={{
                                color: `#000000`,
                              }}
                            >
                              {Delivery.places[element].name}
                            </option>
                          );
                        }
                      })}
                    </Form.Control>
                  </Form.Group>
                </Form> :
                  PlaceIDAdress === null ?
                    <input
                      className="inputs-half mt-3 Lite"
                      type="text"
                      placeholder="Miasto"
                      value={CityAPI}
                      onChange={(e) => {
                        CityAPIValid(e);
                      }}
                      style={{
                        color: `${color}`,
                        borderRadius: "0px",
                        borderBottom: `1px solid ${Color}`,
                      }}
                    /> : null
              }
              {
                PlaceIDAdress === null ? <div className="street-autocomplete-conteiner">
                  <input
                    className="inputs-half1 mt-3 Lite"
                    type="text"
                    placeholder={street}
                    value={Street}
                    onChange={e => {
                      StreetValid(e)
                    }}
                    style={{
                      color: `${color}`,
                      borderRadius: "0px",
                      borderBottom: `1px solid ${Color}`,
                    }}
                  />
                  {loading ? <div className="confirm-adress-btn d-flex justify-content-center align-items-center" ><Spinner animation="border" variant="primary" style={{ height: 23, width: 23 }} /></div> : null}
                  {placesArray.id !== null && !loading && !confirmAdress ? <div className="confirm-adress-btn d-flex flex-column" onClick={() => { selectPleace(placesArray.id) }}> <div className="d-flex justify-content-between align-items-center"> <span className="d-flex justify-content-between align-items-center"> <span> <p className="mb-0 big-label">{placesArray.label}</p> <p className="mb-0 small-label">{placesArray.small_label}</p> </span> </span>  <span>{LoadingFinalPrice ? <Spinner animation="border" variant="primary" style={{ height: 17, width: 17 }} /> : <HeaderIconSelector icon="gm" />}</span></div> {errorMessage !== '' ? <div className="d-flex justify-content-between align-items-center"> <span className="errorText"> {errorMessage}</span> </div> : null}  </div> : null}
                </div> : <p className=" my-1 " >{PlaceIDAdress} <span><button className="btn btn-link" type="primary" onClick={()=>setPlaceIDAdress(null)}><img src={Edit}/> </button></span></p>
              }

              <input
                className="inputs-half my-3 Lite"
                type="text"
                placeholder={bulidingNr}
                value={BuildNr}
                onChange={(e) => {
                  BuildNrValid(e);
                }}
                style={{
                  color: `${color}`,
                  borderRadius: "0px",
                  borderBottom: `1px solid ${Color}`,
                }}
              /></> : ''
          }




          <div className="col-12  Bold px-0">
            {City !== null && City !== undefined && IsAPi === false // api albo klasycznie 
              ? Ammount - OffRedux < FreeAbove
                ? `${DeliveryCostIncluded} ${City.delivery_price.toFixed(
                  2
                )} ${Order_currency}`
                : `${Delivery_is_free}`
              : ""}
          </div>
          <div className="col-12   Bold px-0">
            {City !== null && City !== undefined && IsAPi === false // api albo klasycznie 
              ? `${DeliveryRegionPrice} ${City.free_above.toFixed(
                2
              )} ${Order_currency}`
              : ""}
          </div>
          <div className="col-12  Bold px-0">
            {FreeAbove !== null && FreeAbove !== undefined && IsAPi === true // api albo klasycznie 
              ? Ammount - OffRedux < FreeAbove
                ? `${DeliveryCostIncluded} ${DeliveryAmmount.toFixed(
                  2
                )} ${Order_currency}`
                : `${Delivery_is_free}`
              : ""}
          </div>
          <div className="col-12   Bold px-0">
            {FreeAbove !== null && FreeAbove !== undefined && IsAPi === true // api albo klasycznie 
              ? `${DeliveryRegionPrice} ${FreeAbove.toFixed(
                2
              )} ${Order_currency}`
              : ""}
          </div>
          <div className="col-12   Bold px-0">
            {FreeAbove === null && DeliveryAmmount !== null && DeliveryAmmount !== undefined // api albo klasycznie 
              ? `${DeliveryCostIncluded} ${DeliveryAmmount} ${Order_currency}`
              : ""}
          </div>
          <div
            className="form-check form-check-inline mr-md-5 mr-3 mt-2 Lite position-relative cont3"
            style={{
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            {AtLocation !== null ? <><input
              className="form-check-input d-none"
              type="checkbox"
              id="accept3"
              checked={OrderByMyself}
              onChange={(e) => {
                ChangeTakeOrderType(e);
              }}
              style={{ cursor: "pointer" }}
            />

              <label
                className="form-check-label res"
                htmlFor="accept3"
                style={{
                  border: `2px solid ${color}`,
                  cursor: "pointer",
                }}
              ></label>

              <label
                className="form-check-label  Bold"
                htmlFor="accept3"
                style={{
                  cursor: "pointer",

                  paddingLeft: "30px",
                }}
              >
                {AtLocation.name}
              </label></> : ''}

            <style>
              {`
        .cont3 input:checked + .res {
          border: 2px solid ${color};
        }
        .cont3 input:checked + .res:after {
          content: "";
          position: absolute;
          height: 7px;
          width: 10px;
          border-bottom: 2px solid ${color};
          border-left:  2px solid ${color};
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        
        `}
            </style>
          </div>
        </div>
        <div className={IS_QR ? 'col-md-6 px-0 pt-md-0 my-md-0 mt-3' : 'col-md-6 px-0 pt-md-0 pt-5 my-md-0 mt-3'}>
          <p className="Bold h2 upper Order-font1">{Delivery_client_contact}</p>
          <input
            className="inputs-half1 my-3 Lite mr-0"
            type="text"
            placeholder={name}
            value={Name}
            onChange={(e) => {
              NameValid(e);
            }}
            style={{
              color: `${color}`,
              borderRadius: "0px",
              borderBottom: `1px solid ${Color}`,
              display: IS_QR ? 'none' : 'block'
            }}
          />
          <input
            className="inputs-half my-3 Lite "
            type="text"
            placeholder={surname}
            value={Surname}
            onChange={(e) => {
              SurnameValid(e);
            }}
            style={{
              color: `${color}`,
              display: IS_QR ? 'none' : 'block',
              borderRadius: "0px",
              borderBottom: `1px solid ${Color}`,
            }}
          />
          <input
            className="inputs my-0 Lite"
            type="text"
            placeholder={phone}
            value={Phone}
            onChange={(e) => {
              PhoneValid(e);
            }}
            style={{
              color: `${color}`,
              borderRadius: "0px",
              borderBottom: `1px solid ${Color}`,
            }}
          />
          <input
            className="inputs my-3 Lite"
            type="text"
            placeholder={email}
            value={Email}
            onChange={(e) => {
              EmailValid(e);
            }}
            style={{
              color: `${color}`,
              borderRadius: "0px",
              borderBottom: `1px solid ${Color}`,
            }}
          />

        </div>

      </div>
    </div>
  );
}
