import React, { useState, useEffect } from "react";
import "../CSS/Header.css";
import { useSelector } from "react-redux";
import HeaderIconSelector from "./HeaderIconSelector";
import { Link, animateScroll as scroll } from "react-scroll";


export default function Header({ height, opacity }) {
  const IsOpen =  useSelector((e) => e.UserReducer.isOpen);
  const IsApp =  useSelector((e) => e.UserReducer.is_app);
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyCity, setCompanyCity] = useState("");
  const [HeaderText1, setHeaderText1] = useState("");
  const [HeaderText2, setHeaderText2] = useState("");
  const [CompanyPhoneNr, setCompanyPhoneNr] = useState("");
  const [LogoURL, setLogoURL] = useState("");
  const [Color, setColor] = useState("");
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const Categorier = useSelector((a) => a.MenuReducer.menu);
  const [Additions_font_color, setAdditions_font_color] = useState("");
  const [Additions_start_color, setAdditions_start_color] = useState("");
  const [
    Restaurant_auto_closed_alert,
    setRestaurant_auto_closed_alert,
  ] = useState("");
  const [After_payment_url, setAfter_payment_url] = useState("");
  useEffect(() => {
    if (UserInfo.editable_texts !== undefined) {
      setCompanyName(UserInfo.editable_texts.restaurant_name);
      setCompanyCity(UserInfo.editable_texts.restaurant_city);
      setHeaderText1(UserInfo.editable_texts.header_text_1);
      setHeaderText2(UserInfo.editable_texts.header_text_2);
      setCompanyPhoneNr(UserInfo.editable_texts.restaurant_phone);
      setRestaurant_auto_closed_alert(
        UserInfo.editable_texts.restaurant_auto_closed_alert
      );
    }
    if (UserInfo.img !== undefined) {
      setLogoURL(UserInfo.img.logo);
      // console.log(UserInfo.img.logo);
    }
    if (UserInfo.urls !== undefined) {
      setAfter_payment_url(UserInfo.urls.after_payment_url);
      // console.log(UserInfo.img.logo);
    }
    if (UserInfo.colors !== undefined) {
      setColor(UserInfo.colors.additions_stop_color);
      setAdditions_font_color(UserInfo.colors.additions_font_color);
      setAdditions_start_color(UserInfo.colors.additions_start_color);
    }
  }, [UserInfo]);

  const [StyleRightNavBar, setStyleRightNavBar] = useState("translateX(100%)");
  const changeStyleNav = () => {
    if (StyleRightNavBar === "translateX(100%)") {
      setStyleRightNavBar("translateX(0)");
    } else if (StyleRightNavBar === "translateX(0)") {
      setStyleRightNavBar("translateX(100%)");
    }
  };
  const Alert = useSelector((a) => a.UserReducer.alert);
  //const IsOpen = useSelector((a) => a.UserReducer.isOpen);
  const relacateMinePage = () => {
    if (window.ReactNativeWebView!==undefined){
      
      window.ReactNativeWebView.postMessage("reload");
    }else{
  
      window.location.href = After_payment_url;
    }

    
  };

  return (
    <div
      className="container-fluid header height fixed-top"
      style={{ height: height }}
    >
      <div
        className="container  py-sm-1 py-0 d-flex position-relative height"
        style={{ height: height }}
      >
        <img
          onClick={relacateMinePage}
          style={{ cursor: "pointer" }}
          src={LogoURL}
          id="logo"
          className="d-block  mr-sm-4 mr-2"
        />

        <span className="d-flex align-items-center">
          <span>
            <p className="h5 Bold upper mb-0 mb-sm-2" id="header_text_1">
              {CompanyName}
            </p>
            <p className="h6 Lite upper mb-0" id="header_text_2">
              {CompanyCity}
            </p>
          </span>
        </span>
        <span
          className="d-flex align-items-center float-right mx-auto text-center resteurantDisc"
          style={{ opacity: opacity }}
        >
          <span>
            <p className="h5 Lite">{HeaderText1}</p>
            <p className="h5 Lite">{HeaderText2}</p>
          </span>
        </span>
        <span className="d-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            id="arrow"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <defs>
              <linearGradient
                id="a"
                x1="0.5"
                x2="0.5"
                y2="1"
                gradientUnits="objectBoundingBox"
              ></linearGradient>
            </defs>
            <path
              d="M646,3450H621.66l11.18-11.179L630,3436l-16,16,16,16,2.82-2.82L621.66,3454H646Z"
              transform="translate(-614 -3436)"
            />
          </svg>
        </span>
        <span className="">
          <svg viewBox="0 0 48 33.349" id="menu-row" onClick={changeStyleNav}>
            <defs></defs>
            <g transform="translate(9.814)">
              <g transform="translate(-7.314 22.93)">
                <circle
                  className="circle-a"
                  cx="5.209"
                  cy="5.209"
                  r="5.209"
                  transform="translate(31.256)"
                />
                <circle
                  className="circle-b"
                  cx="5.209"
                  cy="5.209"
                  r="5.209"
                  transform="translate(15.628)"
                />
                <circle className="circle-c" cx="5.209" cy="5.209" r="5.209" />
              </g>
              <text className="d" transform="translate(-9.814 15)">
                <tspan x="0" y="0">
                  MENU
                </tspan>
              </text>
            </g>
          </svg>
        </span>
      </div>
      <div className="container px-0  py-0 d-flex position-relative height">
        <div className="row w-100">
          <div className="col-12 px-0">
            <div
              className="alert alert-danger w-100 py-3 my-0"
              role="alert"
              style={{
                display:
                  IsOpen === null
                    ? "none"
                    : IsOpen === false
                    ? "block"
                    : "none",
                background: `transparent linear-gradient(180deg, ${Additions_start_color} 0%, ${Color} 100%) 0% 0% no-repeat padding-box`,
                color: `${Additions_font_color}`,
                border: "none",
              }}
            >
              {Restaurant_auto_closed_alert}
            </div>{" "}
          </div>
          <div className="col-12 px-0">
            <div
              className="alert alert-danger w-100 py-2 my-0"
              role="alert"
              style={{ display: Alert === null ? "none" : "block" }}
            >
              {Alert}
            </div>{" "}
          </div>
        </div>
      </div>
      <div
        id="right-navbar"
        className="py-4 px-2 d-flex align-items-end flex-column"
        style={{ transform: StyleRightNavBar }}
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 20.001 20.001"
          onClick={changeStyleNav}
          id="menu-x"
          className="mr-4"
        >
          <path
            id="Icon"
            d="M10,20A10,10,0,1,1,20,10,10.012,10.012,0,0,1,10,20ZM10,2a8,8,0,1,0,8,8A8.009,8.009,0,0,0,10,2ZM6.41,15h0L5,13.59,8.59,10,5,6.41,6.411,5,10,8.59,13.59,5,15,6.41,11.41,10,15,13.59,13.591,15,10,11.41,6.411,15Z"
          />
        </svg>
        <div className="row mt-5 mb-auto" id="navbar-category">
          {Categorier!==[]&&Categorier!==null&&Categorier!==undefined?Categorier.map((element) => (
            <Link
              activeClass="active"
              className="my-1"
              to={element.id}
              spy={true}
              smooth={true}
              offset={-100}
              duration={300}
              onClick={changeStyleNav}
              style={{ cursor: "pointer" }}
            >
              <div
                className="col-12 d-flex align-items-center my-0"
                style={{ cursor: "pointer" }}
              >
                <span className="mr-0">
                  <HeaderIconSelector color={Color} icon={element.icon} />
                </span>
                <span>{element.name}</span>
              </div>
            </Link>
          )):''}
        </div>
        <div className="row w-100">
          <div className="col-12 d-flex align-items-center my-2">
            <span className="mr-3">
              <svg width="56" height="56" viewBox="0 0 56 56">
                <g transform="translate(-578 -450)">
                  <g transform="translate(456 -2792.348)">
                    <rect
                      className="a"
                      width="31"
                      height="31"
                      transform="translate(134 3254.349)"
                    />
                    <path
                      d="M22.205,23.511a22.06,22.06,0,0,1-15.7-6.5A22.061,22.061,0,0,1,0,1.306,1.307,1.307,0,0,1,1.306,0H5.878A1.307,1.307,0,0,1,7.184,1.306a14.834,14.834,0,0,0,.745,4.663A1.306,1.306,0,0,1,7.6,7.3L4.728,10.175a19.893,19.893,0,0,0,8.609,8.607l2.873-2.873a1.342,1.342,0,0,1,.928-.378,1.078,1.078,0,0,1,.4.065,14.851,14.851,0,0,0,4.663.744,1.307,1.307,0,0,1,1.306,1.306V22.2A1.308,1.308,0,0,1,22.205,23.511Zm-4.7-5.2-1.567,1.554a20.168,20.168,0,0,0,4.963.98V18.9a16.63,16.63,0,0,1-3.4-.587ZM2.652,2.611a19.435,19.435,0,0,0,.992,4.951L5.211,5.994a16.087,16.087,0,0,1-.587-3.383Z"
                      transform="translate(137.918 3257.919)"
                    />
                  </g>
                  <rect
                    className="a"
                    width="56"
                    height="56"
                    transform="translate(634 506) rotate(180)"
                  />
                </g>
              </svg>
            </span>
            <span>
              <p className="mb-0 Lite">{CompanyPhoneNr}</p>
            </span>
          </div>
          <div className="col-12 d-flex align-items-center my-2">
            <span className="mr-3">
              <img src={LogoURL} className="d-block logo_navbar" />
            </span>
            <span>
              <p className="mb-0 Bold upper">{CompanyName}</p>
              <p className="mb-0 Lite upper">{CompanyCity}</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
