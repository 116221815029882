import React, {useState} from 'react'
import { SetAlert } from "../store/UserReducer";
import { useDispatch } from "react-redux";
export default function SingleCheckBox({
    element,
    color,
    add,
    remove,
    lock
  }) {
    const dispatch = useDispatch();
    const [Value, setValue] = useState(false)
    const echo = (e, element) => {
    //console.log(e.target.checked)
        if (e.target.checked) {
          if (!lock) { 
          //console.log("true");
          //console.log(element);
          setValue(true)
          add(element);
          }else{
            setValue(false)
            SetAlert("Nie można dodać więcej składników",dispatch)
          }
        } else {
          //console.log("fasle");
          //console.log(element);
          setValue(false)
          remove(element);
        }
    };
    return (
        <div
        key={element.name + element.id}
        className="cont  mr-md-5 mr-3 mt-3 Lite position-relative "
        style={{
          cursor: "pointer",
          userSelect: "none",
        }}
      >
        <input
          className="form-check-input d-none"
          type="checkbox"
          id={element.name + element.id}
            checked={Value}
          onChange={(e) => {
            echo(e, element);
          }}
          style={{
            cursor: "pointer",
          }}
        />

        <label
          className={`form-check-label res`} 
          htmlFor={element.name + element.id}
          style={{
            cursor: "pointer",
          }}
        ></label>

        <label
          className="form-check-label res22"
          htmlFor={element.name + element.id}
          style={{
            cursor: "pointer",
          }}
        >
          {element.name}
        </label>
        <style>
      {`
        .cont input:checked + .res {
          border: 2px solid ${color};
        }
        .cont input:checked + .res:after {
          content: "";
          position: absolute;
          height: 7px;
          width: 10px;
          border-bottom: 2px solid ${color};
          border-left: 2px solid ${color};
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        
        `}
    </style>
      </div>
    )
}
