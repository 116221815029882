import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import MenuReducer from "./MenuREducer";
import OrderReducer from "./OrderReducer";
const rootReducer = combineReducers({
  UserReducer,
  MenuReducer,
  OrderReducer,
});
export default rootReducer;
