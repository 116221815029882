export const ALL_DATA = "ALL_DATA";
export const SET_COUPNS = "SET_COUPNS";
export const SET_ALERT = "SET_ALERT";
export const SET_MODE = "SET_MODE";
export const SET_IS_OPEN = "SET_IS_OPEN";
export const SET_LOADING1 = "SET_LOADING1";
export const SET_LOADING2 = "SET_LOADING2";
export const SET_LOADING3 = "SET_LOADING3";
export const SET_RESTEURANTCODE = "SET_RESTEURANTCODE";
export const SET_ISOPEN = "SET_ISOPEN";
export const SET_G_Analistic_ID = "SET_G_Analistic_ID";
export const SET_IS_API = "SET_IS_API";
export const SET_IS_FOOFTRUCK = "SET_IS_FOOFTRUCK";
export const SET_FOOFTRUCK_AGREE = "SET_FOOFTRUCK_AGREE";
export const SET_IS_APP = "SET_IS_APP";
export const SET_IS_QR = "SET_IS_QR";
const initialState = {
  allData: {},
  resturantCode: null,
  is_api: null,
  is_app: null,
  is_qr: null,
  is_foodtruck: null,
  foodtruck_location_agree: null,
  coupons: {},
  alert: null,
  url: "https://resmanager.srv22208.microhost.com.pl",
  server_URL: "https://seahorse-app-47fzu.ondigitalocean.app",
  //server_URL: "https://s1.flexmenu.pl",
  google_analistic_ID: null,
  isOpen: null,
  loading1: false,
  loading2: false,
  loading3: false,
}; 

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_FOOFTRUCK_AGREE": {
      return {
        ...state,
        foodtruck_location_agree: action.payload,
      };
    }
    case "SET_IS_FOOFTRUCK": {
      return {
        ...state,
        is_foodtruck: action.payload,
      };
    }
    case "SET_IS_API": {
      return {
        ...state,
        is_api: action.payload,
      };
    }
    case "SET_IS_QR": {
      return {
        ...state,
        is_qr: action.payload,
      };
    }
    case "SET_IS_APP": {
      return {
        ...state,
        is_app: action.payload,
      };
    }
    case "ALL_DATA": {
      return {
        ...state,
        allData: action.payload,
      };
    }
    case "SET_COUPNS": {
      return {
        ...state,
        coupons: action.payload,
      };
    }
    case "SET_G_Analistic_ID": {
      return {
        ...state,
        google_analistic_ID: action.payload,
      };
    }
    case "SET_ALERT": {
      return {
        ...state,
        alert: action.payload,
      };
    }
    case "SET_MODE": {
      return {
        ...state,
        mode: action.payload,
      };
    }
    case "SET_IS_OPEN": {
      return {
        ...state,
        isOpen: action.payload,
      };
    }
    case "SET_LOADING1": {
      return {
        ...state,
        loading1: action.payload,
      };
    }
    case "SET_LOADING2": {
      return {
        ...state,
        loading2: action.payload,
      };
    }
    case "SET_LOADING3": {
      return {
        ...state,
        loading3: action.payload,
      };
    }
    case "SET_RESTEURANTCODE": {
      return {
        ...state,
        resturantCode: action.payload,
      };
    }
    case "SET_ISOPEN": {
      return {
        ...state,
        isOpen: action.payload,
      };
    }
    default: {
      //   console.log(`Unknown action ${action.type}`);
      return {
        ...state,
      };
    }
  }
};

export const SetFoodtruckAgree = (foodtruck_location_agree, dispatch) => {
  if (
    dispatch({ type: SET_FOOFTRUCK_AGREE, payload: foodtruck_location_agree })
  ) {
    return true;
  }
};
export const SetIsFoodtruck = (is_foodtruck, dispatch) => {
  if (dispatch({ type: SET_IS_FOOFTRUCK, payload: is_foodtruck })) {
    return true;
  }
};
export const SetIsApi = (is_api, dispatch) => {
  if (dispatch({ type: SET_IS_API, payload: is_api })) {
    return true;
  }
};
export const SetIsQr = (is_qr, dispatch) => {
  if (dispatch({ type: SET_IS_QR, payload: is_qr })) {
    return true;
  }
};
export const SetIsApp = (is_app, dispatch) => {
  if (dispatch({ type: SET_IS_APP, payload: is_app })) {
    return true;
  }
};
export const AllData = (allData, dispatch) => {
  if (dispatch({ type: ALL_DATA, payload: allData })) {
    return true;
  }
};
export const SetG_A_ID = (google_analistic_ID, dispatch) => {
  if (dispatch({ type: SET_G_Analistic_ID, payload: google_analistic_ID })) {
    return true;
  }
};
export const SetCoupons = (coupons, dispatch) => {
  if (dispatch({ type: SET_COUPNS, payload: coupons })) {
    return true;
  }
};
export const SetAlert = (alert, dispatch) => {
  if (dispatch({ type: SET_ALERT, payload: alert })) {
    return true;
  }
};
export const SetMode = (mode, dispatch) => {
  if (dispatch({ type: SET_MODE, payload: mode })) {
    return true;
  }
};
export const SetLoading1 = (loading1, dispatch) => {
  if (dispatch({ type: SET_LOADING1, payload: loading1 })) {
    return true;
  }
};
export const SetLoading2 = (loading2, dispatch) => {
  if (dispatch({ type: SET_LOADING2, payload: loading2 })) {
    return true;
  }
};
export const SetLoading3 = (loading3, dispatch) => {
  if (dispatch({ type: SET_LOADING3, payload: loading3 })) {
    return true;
  }
};
export const SetResteurantCode = (resturantCode, dispatch) => {
  if (dispatch({ type: SET_RESTEURANTCODE, payload: resturantCode })) {
    return true;
  }
};
export const SetIsOpen = (isOpen, dispatch) => {
  if (dispatch({ type: SET_ISOPEN, payload: isOpen })) {
    return true;
  }
};
