import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
export default function IconsMineDisplay() {
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const URL = useSelector((e) => e.UserReducer.url);
  const [Icone1, setIcone1] = useState("");
  const [Icone2, setIcone2] = useState("");
  const [Icone3, setIcone3] = useState("");
  const [Icone4, setIcone4] = useState("");
  const [Icone5, setIcone5] = useState("");
  const [Icone6, setIcone6] = useState("");
  const [IconTitle1, setIconTitle1] = useState("");
  const [IconTitle2, setIconTitle2] = useState("");
  const [IconTitle3, setIconTitle3] = useState("");
  const [IconTitle4, setIconTitle4] = useState("");
  const [IconTitle5, setIconTitle5] = useState("");
  const [IconTitle6, setIconTitle6] = useState("");
  const [Icons_legend_title, setIcons_legend_title] = useState("");
  useEffect(() => {
    if (UserInfo.editable_texts !== undefined) {
      setIconTitle1(UserInfo.editable_texts.icon_1_title);
      setIconTitle2(UserInfo.editable_texts.icon_2_title);
      setIconTitle3(UserInfo.editable_texts.icon_3_title);
      setIconTitle4(UserInfo.editable_texts.icon_4_title);
      setIconTitle5(UserInfo.editable_texts.icon_5_title);
      setIconTitle6(UserInfo.editable_texts.icon_6_title);
      setIcons_legend_title(UserInfo.editable_texts.icons_legend_title);
    }
    if (UserInfo.img !== undefined) {
      setIcone1(URL + UserInfo.img.icon1);
      setIcone2(URL + UserInfo.img.icon2);
      setIcone3(URL + UserInfo.img.icon3);
      setIcone4(URL + UserInfo.img.icon4);
      setIcone5(URL + UserInfo.img.icon5);
      setIcone6(URL + UserInfo.img.icon6);
      // console.log(UserInfo.img.logo);
    }
  }, [UserInfo]);
  return (
    <div className="row px-md-4 pb-5  mb-5 ">
      {Icons_legend_title !== "" ? (
        <div className="col-11 mx-0 px-0">
          <h3 className="upper Bold mt-4 w-100 text-right">
            {Icons_legend_title}
          </h3>
        </div>
      ) : (
        ""
      )}
      <div className="row px-md-0 w-100  ">
        <div className="col-11 mx-0 px-0 d-flex justify-content-end flex-wrap">
          {IconTitle1 !== "" ? (
            <div className="d-flex align-items-center ml-3  ">
              <img src={Icone1} className="icon-img" />
              <span className="Lite  mb-0">{IconTitle1}</span>
            </div>
          ) : (
            ""
          )}
          {IconTitle2 !== "" ? (
            <div className="d-flex align-items-center ml-3  ">
              <img src={Icone2} className="icon-img" />
              <span className="Lite  mb-0">{IconTitle2}</span>
            </div>
          ) : (
            ""
          )}
          {IconTitle3 !== "" ? (
            <div className="d-flex align-items-center ml-3  ">
              <img src={Icone3} className="icon-img" />
              <span className="Lite  mb-0">{IconTitle3}</span>
            </div>
          ) : (
            ""
          )}
          {IconTitle4 !== "" ? (
            <div className="d-flex align-items-center ml-3  ">
              <img src={Icone4} className="icon-img" />
              <span className="Lite  mb-0">{IconTitle4}</span>
            </div>
          ) : (
            ""
          )}
          {IconTitle5 !== "" ? (
            <div className="d-flex align-items-center ml-3  ">
              <img src={Icone5} className="icon-img" />
              <span className="Lite  mb-0">{IconTitle5}</span>
            </div>
          ) : (
            ""
          )}
          {IconTitle6 !== "" ? (
            <div className="d-flex align-items-center ml-3  ">
              <img src={Icone6} className="icon-img" />
              <span className="Lite  mb-0">{IconTitle6}</span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>{" "}
    </div>
  );
}
