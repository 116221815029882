import React, { useEffect, useState } from "react";
import Header from "./Header";
import Display from "./Display";
import { useSelector } from "react-redux";

import "../CSS/Mine.css";
export default function Mine() {
  const UserInfo = useSelector((e) => e.UserReducer.allData);

  const [Background, setBackground] = useState("");
  useEffect(() => {
    if (UserInfo.img !== undefined) {
      setBackground(UserInfo.img.background);
    }
  }, [UserInfo]);

  const [Height, setHeight] = useState(
    window.innerWidth >= 1400 ? 100 : 70
  );
  const [Opacity, setOpacity] = useState(1);


  return (
    <div
      className="container-fluid mine-background "
      style={{ backgroundImage: `url(${Background})` }}
    >
      <Header height={Height} opacity={Opacity} />
      <Display height={Height} />
    </div>
  );
}
