import React, { useEffect, useState } from "react";
import "./App.css";
import Mine from "./Components/Mine";
import axios from "axios";
import { SetMenuCategory } from "./store/MenuREducer";
import Loading from "./Components/Loading";
import {
  SetCity,
  SetStreet,
  SetBuildNr,
  SetName,
  SetSurame,
  SetPhone,
  SetEmail,
  SetaAtLocation
} from "./store/OrderReducer";
import {
  SetG_A_ID,
  AllData,
  SetCoupons,
  SetLoading3,
  SetLoading2,
  SetLoading1,
  SetResteurantCode,
  SetIsApi,
  SetIsFoodtruck
} from "./store/UserReducer";
import { setDelivery } from "./store/OrderReducer";
import { useDispatch, useSelector } from "react-redux";
import ReactGa from 'react-ga'
import { SetIsOpen } from "./store/UserReducer";
function App() {

  const G_A_ID = useSelector((e) => e.UserReducer.google_analistic_ID);
  useEffect(() => {
    if (G_A_ID !== null) {
      ReactGa.initialize(G_A_ID)
      ReactGa.pageview('/')
    }
  }, [G_A_ID])
  const ResteurantCode = useSelector((e) => e.UserReducer.resturantCode);
  const serwer_URL = useSelector((e) => e.UserReducer.server_URL);
  const URL = useSelector((e) => e.UserReducer.url);
  const LOADING1 = useSelector((e) => e.UserReducer.loading1);
  const LOADING2 = useSelector((e) => e.UserReducer.loading2);
  const LOADING3 = useSelector((e) => e.UserReducer.loading3);
  const [IsOpen, setIsOpen] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {

    getAllMenu();
    getAllTexts();

    SetIsOpen(setOpenClose(), dispatch)

  }, [IsOpen]);
  const GetStorageData = (de, isTruck) => {
    if (localStorage.getItem('City') !== null && localStorage.getItem('City') !== undefined && isTruck === false) {
      SetCity(de[localStorage.getItem('City')], dispatch);
      console.log(de[localStorage.getItem('City')])
    }
    if (localStorage.getItem('Street') !== null && localStorage.getItem('Street') !== undefined && isTruck === false) {
      SetStreet(localStorage.getItem('Street'), dispatch);
    }
    if (localStorage.getItem('BuildNr') !== null && localStorage.getItem('BuildNr') !== undefined && isTruck === false) {
      SetBuildNr(localStorage.getItem('BuildNr'), dispatch);
    }
    if (localStorage.getItem('Name') !== null && localStorage.getItem('Name') !== undefined) {
      SetName(localStorage.getItem('Name'), dispatch);
    }
    if (localStorage.getItem('Surname') !== null && localStorage.getItem('Surname') !== undefined) {
      SetSurame(localStorage.getItem('Surname'), dispatch);
    }
    if (localStorage.getItem('Phone') !== null && localStorage.getItem('Phone') !== undefined) {
      SetPhone(localStorage.getItem('Phone'), dispatch);
    }
    if (localStorage.getItem('Email') !== null && localStorage.getItem('Email') !== undefined) {
      SetEmail(localStorage.getItem('Email'), dispatch);
    }

  }
  const setOpenClose = () => {
    axios
      .get(`${URL}/get_open_close.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response.data);

        if (response.data.is_open.auto_open_close) {
          let d = new Date();
          let n = d.getDay();

          let h = timeToDecimal(
            d.getHours().toString() + ":" + d.getMinutes().toString()
          );
          // //console.log("--------------------------");

          if (
            response.data.opening_hour[n] !== "-" &&
            response.data.closing_hour[n] !== "-"
          ) {
            //  //console.log(n);
            let min = timeToDecimal(response.data.opening_hour[n]);
            // //console.log(min);
            let max = timeToDecimal(response.data.closing_hour[n]);
            //   //console.log(max);
            if (h > min && h < max) {
              //  SetIsOpen(true, dispatch);
              setIsOpen(true);
            } else {
              //  SetIsOpen(false, dispatch);
              setIsOpen(false);
            }
          } else {
            setIsOpen(false);
          }
        } else {
          //  SetIsOpen(response.data.is_open.is_open, dispatch);
          //  SetMode(response.data.is_open.auto_open_close, dispatch);
          // //console.log("");
          setIsOpen(response.data.is_open.is_open);

          // sprwadzaj czy ustawia i jesli true to apka załadowana
        }

        // SetMode(response.data.is_open.auto_open_close, dispatch)
        //   SetIsOpen(response.data.is_open.is_open, dispatch)
      })

      .catch((err) => {
        //console.log(err.response);
        setIsOpen(null);
      });

    return IsOpen;


  }
  const timeToDecimal = (t) => {
    t = t.split(":");
    return parseFloat(parseInt(t[0], 10) + parseInt(t[1], 10) / 60);
  };
  const getAllMenu = async () => {
    await axios
      .get(`${URL}/get_menu.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
       console.log(response.data);
        if (
          SetMenuCategory(response.data.menu, dispatch) &&
          SetCoupons(response.data.coupons, dispatch)
        ) {
          SetLoading1(true, dispatch);
        }
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  const getAllTexts = async () => {
    await axios
      .get(`${URL}/get_website_data.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response.data);

        if (
          AllData(response.data, dispatch) &&
          IsOpen !== null &&
          SetResteurantCode(response.data.restaurant_info.code, dispatch) &&
          SetG_A_ID(response.data.editable_texts.google_analistic_ID, dispatch)
        ) {
          getDeliveryPlaces(response.data.restaurant_info.code);
          SetLoading2(true, dispatch);
        }
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  const getDeliveryPlaces = async (value) => {
    await axios
      .get(`${URL}/get_delivery_places.php`, {  //TODO serwer powinien edytwać resmanagera (sront ma orzystać tlyko z resmanagera)
      //.get(`${serwer_URL}/${value}/delivery_places`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
   
        if (setDelivery(response.data, dispatch) && SetIsApi(response.data.uses_google_maps, dispatch) && SetIsFoodtruck(response.data.is_foodtruck, dispatch)) { // SetIsApi => response.data.uses_google_maps
          SetLoading3(true, dispatch);
          if(localStorage.getItem('PlaceID')!==undefined && !response.data.uses_google_maps){
            localStorage.clear()
          }
          GetStorageData(response.data.places, response.data.is_foodtruck)


          if (response.data.uses_google_maps) {
            SetaAtLocation({
              delivery_price: 0,
              free_above: 0,
              id: null,
              is_place: true,
              minimal_price: 0,
              name: "Odbiór osobisty w restauracji",
              postal_code: "",
              restaurant: 3
            }, dispatch)
          }


        }
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };

  if (
    LOADING1 === true &&
    LOADING2 === true &&
    LOADING3 === true &&
    IsOpen !== null
  ) {
    return <Mine />;
  } else {
    return <Loading />;
  }
}

export default App;
