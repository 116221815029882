import React, { useState, useEffect } from "react";
import { Spring } from "react-spring/renderprops";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { SetFoodtruckAgree } from "../store/UserReducer";
function Info({ test, history, match }) {
  const dispatch = useDispatch();
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const [Additions_font_color, setAdditions_font_color] = useState("");
  const [Additions_start_color, setAdditions_start_color] = useState("");
  const [Additions_stop_color, setAdditions_stop_color] = useState("");
  const [InformationTitle, setInformationTitle] = useState('');
  const [InformationText, setInformationText] = useState('');

  const [
    Back_from_confirmation_button,
    setBack_from_confirmation_button,
  ] = useState("");
  const [Order_error_title, setOrder_error_title] = useState("");
  const [Online_order_error_text, setOnline_order_error_text] = useState("");
  const [Restaurant_inactive_error, setRestaurant_inactive_error] = useState(
    ""
  );
  const [After_payment_url, setAfter_payment_url] = useState("");
  useEffect(() => {
    if (UserInfo.colors !== undefined) {
      setAdditions_font_color(UserInfo.colors.additions_font_color);
      setAdditions_start_color(UserInfo.colors.additions_start_color);
      setAdditions_stop_color(UserInfo.colors.additions_stop_color);
    }
    if (UserInfo.editable_texts !== undefined) {
      setInformationTitle(UserInfo.editable_texts.information_title);
      setInformationText(
        UserInfo.editable_texts.information_text
      );
    }
    if (UserInfo.constant_texts !== undefined) {
      setBack_from_confirmation_button(
        UserInfo.constant_texts.back_from_confirmation_button
      );
      setRestaurant_inactive_error(
        UserInfo.constant_texts.restaurant_inactive_error
      );
    }
    if (UserInfo.urls !== undefined) {
      setAfter_payment_url(UserInfo.urls.after_payment_url);
      // console.log(UserInfo.img.logo);
    }
  }, [UserInfo]);
  const goToMinePage = () => {
if(SetFoodtruckAgree(true,dispatch)){
    history.push("/");
}

  };
  return (
    <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
      {(props) => (
        <div
          className="my-0 pt-0 pb-5 d-flex align-items-center"
          style={{
            props,
            background: `transparent linear-gradient(180deg, ${Additions_start_color} 0%, ${Additions_stop_color} 100%) 0% 0% no-repeat padding-box`,
            color: `${Additions_font_color}`,
            minHeight: window.innerHeight - test,
          }}
        >
          <div className="row px-3 py-2 w-100">
            <div className="col-12 text-center  mt-5  mb-2">
              <p className="h1 Bold">{InformationTitle}</p>
            </div>
            <div className="col-sm-6 offset-sm-3 text-center">
              <p className="h6 Lite">
                {InformationText}
              </p>
            </div>
            <div className="col-sm-4 offset-sm-4 text-center mt-3  mb-2">
              <button
                type="button"
                style={{
                  color: `${Additions_stop_color}`,
                  backgroundColor: `${Additions_font_color}`,
                }}
                className=" editButton Bold upper py-2"
                onClick={() => {
                  goToMinePage();
                }}
              >
                Chcę zamówić z odbiorem
              </button>{" "}
            </div>
          </div>
        </div>
      )}
    </Spring>
  );
}
export default withRouter(Info);
