import React from "react";
import SingleCheckBox from "./SingleCheckBox";
export default function CheckBoxComponent({
  options,
  color,
  add,
  remove,
  lock
}) {

return (
  <>
    {options.map((element) => (
     <SingleCheckBox element={element} color={color} add={add} remove={remove} lock={lock}/>
    ))}
 
  </>
);
}
