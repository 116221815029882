import React, { useState, useEffect } from "react";
import "../CSS/Footer.css";
import { useSelector } from "react-redux";
import round from "../Functions/round";
import { Link, animateScroll as scroll } from "react-scroll";
import HeaderIconSelector from "./HeaderIconSelector";
export default function Footer() {
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const [LogoURL, setLogoURL] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyCity, setCompanyCity] = useState("");
  const [CompanyPhoneNr, setCompanyPhoneNr] = useState("");
  const [CompanyNIP, setCompanyNIP] = useState("");
  const [CompanyEmail, setCompanyEmail] = useState("");
  const [CompanyStreet, setCompanyStreet] = useState("");
  const [CompanyOpenText, setCompanyOpen] = useState("");
  const [CompanyOpenHours, setCompanyOpenHours] = useState([]);
  const [CompanyCloseHours, setCompanyCloseHours] = useState([]);
  const [DayNumber, setDayNumber] = useState();
  const [Additions_font_color, setAdditions_font_color] = useState("");
  const [Additions_start_color, setAdditions_start_color] = useState("");
  const [Additions_stop_color, setAdditions_stop_color] = useState("");
  const [Facebook_link, setFacebook_link] = useState("");
  const [Instagram_link, setInstagram_link] = useState("");
  const [Restaurant_nip, setRestaurant_nip] = useState("");
  const [Visit_socials, setVisit_socials] = useState("");
  const [Site_regulations_link, setSite_regulations_link] = useState("");
  const [Site_regulations, setSite_regulations] = useState("");
  const [Site_proviacy_policy_link, setSite_proviacy_policy_link] = useState(
    ""
  );
  const [Site_privacy_policy, setSite_privacy_policy] = useState("");
  const [Dotpay_addition, setDotpay_addition] = useState("");
  const [Feedweb_addition, setFeedweb_addition] = useState("");
  const [Order_currency, setOrder_currency] = useState("");
  const [Basket_text, setBasket_text] = useState("");
  const [DayArray, setDayArray] = useState("");
  useEffect(() => {
    if (UserInfo.constant_texts !== undefined) {
      setRestaurant_nip(UserInfo.constant_texts.restaurant_nip);
      setSite_regulations_link(UserInfo.constant_texts.site_regulations_link);
      setSite_regulations(UserInfo.constant_texts.site_regulations);
      setSite_proviacy_policy_link(
        UserInfo.constant_texts.site_proviacy_policy_link
      );
      setSite_privacy_policy(UserInfo.constant_texts.site_privacy_policy);
      setDotpay_addition(UserInfo.constant_texts.dotpay_addition);
      setFeedweb_addition(UserInfo.constant_texts.feedweb_addition);
      setOrder_currency(UserInfo.constant_texts.order_currency);
      setBasket_text(UserInfo.constant_texts.basket_text);
    }
    if (UserInfo.editable_texts !== undefined) {
      setCompanyName(UserInfo.editable_texts.restaurant_name);
      setCompanyCity(UserInfo.editable_texts.restaurant_city);
      setCompanyPhoneNr(UserInfo.editable_texts.restaurant_phone);
      setCompanyNIP(UserInfo.editable_texts.restaurant_nip);
      setCompanyEmail(UserInfo.editable_texts.restaurant_email);
      setCompanyStreet(UserInfo.editable_texts.restaurant_street);
      setCompanyOpen(UserInfo.editable_texts.restaurant_open_hours);
      setFacebook_link(UserInfo.editable_texts.facebook_link);
      setInstagram_link(UserInfo.editable_texts.instagram_link);
      setVisit_socials(UserInfo.editable_texts.visit_socials);
    }
    if (UserInfo.img !== undefined) {
      setLogoURL(UserInfo.img.logo);
    }
    if (UserInfo.opening_hour !== undefined) {
      setCompanyOpenHours(UserInfo.opening_hour);
      let d = new Date();
      let n = d.getDay();
      setDayNumber(n);
    }
    if (UserInfo.closing_hour !== undefined) {
      setCompanyCloseHours(UserInfo.closing_hour);
    }
    if (UserInfo.colors !== undefined) {
      setAdditions_font_color(UserInfo.colors.additions_font_color);
      setAdditions_start_color(UserInfo.colors.additions_start_color);
      setAdditions_stop_color(UserInfo.colors.additions_stop_color);
    }
    if (UserInfo.week_day_name !== undefined) {
      setDayArray(UserInfo.week_day_name);
    }
  }, [UserInfo]);
  const Order = useSelector((e) => e.OrderReducer.order);
  const City = useSelector((e) => e.OrderReducer.city);
  const OffRedux = useSelector((e) => e.OrderReducer.off);
  const Ammount = useSelector((e) => e.OrderReducer.ammount);
  const FreeAbove = useSelector((e) => e.OrderReducer.freeAbove);

  return (
    <div className="container-fluid ">
      <div className="container footer px-0 ">
        <div className="px-4 mt-5 mb-2 d-md-none d-block">
          <img src={LogoURL} id="logo-footer" alt="" className=" " />
        </div>
        <div className="row pt-3 pt-md-5 pb-md-5 pb-3 px-4">
          <div className="col-md-3 mb-md-3 mb-1 mb-md-0 px-0 h-auto d-md-block d-none">
            <img src={LogoURL} id="logo-footer" alt="" className=" " />
          </div>

          <div className="col-md-9 px-0">
            <h3 className="Bold mb-md-2 mb-3  footer-font2"> {CompanyName}</h3>
            <div className="row ">
              <div className="col-lg-4 col-md-6 mb-md-4 mb-2 px-0">
                <p className="mb-1 Bold footer-font1">
                  {DayArray[DayNumber]}:{" "}
                  {CompanyOpenHours[DayNumber] !== "-" &&
                  CompanyCloseHours[DayNumber] !== "-"
                    ? `${CompanyOpenHours[DayNumber]} - ${CompanyCloseHours[DayNumber]}`
                    : "Nieczynne"}
                </p>
                <p className="mb-1 Lite footer-font1"> {CompanyStreet}</p>
                <p className="mb-1 Lite footer-font1">{CompanyCity}</p>
              </div>
              <div className="col-lg-4 col-md-6 mb-md-4 mb-2 px-0">
                <p className="mb-1 Bold footer-font1">{CompanyPhoneNr}</p>
                <p className="mb-1 Lite footer-font1"> {CompanyEmail}</p>
                <p className="mb-1 Lite footer-font1">
                  {" "}
                  {Restaurant_nip} {CompanyNIP}
                </p>
              </div>
              <div className="col-md-4 mb-md-4 mb-2 px-0 Lite  ">
                <p className="mb-1 Lite footer-font1"> {Visit_socials}</p>
                {Facebook_link !== "" ? (
                  <a href={Facebook_link} target="_blank">
                    <HeaderIconSelector icon="fb" />
                  </a>
                ) : (
                  ""
                )}
                {Instagram_link !== "" ? (
                  <a href={Instagram_link} target="_blank">
                    {" "}
                    <HeaderIconSelector icon="ig" />
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row pl-md-5 pl-0">
          <div className="col-md-8 pl-md-5 pl-4 ">
            <div className="row px-0 ">
              <div className="col-md-4  px-0 mb-md-0 mb-3">
                <a href={Site_regulations_link} id="ah">
                  {" "}
                  <p className="mb-1 Lite size-1">
                    <u> {Site_regulations}</u>{" "}
                  </p>
                </a>
                <a href={Site_proviacy_policy_link} id="ah">
                  {" "}
                  <p className="mb-1 Lite size-1">
                    <u> {Site_privacy_policy}</u>
                  </p>
                </a>
              </div>
              <div className="col-md-4  px-0 mb-3">
                <p className=" size-1 Lite  my-0">{Dotpay_addition}</p>
              </div>
              <div className="col-4 px-0 "></div>
            </div>
          </div>
          <div
            className="col-md-4  d-flex py-0 align-items-center fw-footer"
            style={{
              background: `transparent linear-gradient(180deg, ${Additions_start_color} 0%, ${Additions_stop_color} 100%) 0% 0% no-repeat padding-box`,
              color: `${Additions_font_color}`,
              zIndex:'1000',
              cursor:'pointer'
            }}
            onClick={()=>{
              window.location.href = "https://flexmenu.pl/";
            }}
          >
 

<svg xmlns="http://www.w3.org/2000/svg"  width="80" height="80" viewBox="0 0 80 80">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_2999" data-name="Rectangle 2999" width="48" height="48" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_697" data-name="Group 697" transform="translate(6963 2808)">
    <g id="Group_696" data-name="Group 696" transform="translate(-9 -12)">
      <g id="Group_695" data-name="Group 695" transform="translate(-6938 -2780)">
        <g id="Group_694" data-name="Group 694" clipPath="url(#clip-path)">
          <path id="Path_393" data-name="Path 393" d="M42.989,24.485a3.151,3.151,0,1,1,3.151-3.151,3.155,3.155,0,0,1-3.151,3.151m0-4.363A1.212,1.212,0,1,0,44.2,21.333a1.213,1.213,0,0,0-1.212-1.212" transform="translate(-13.279 -6.061)" style={{ fill: `${Additions_font_color}` }}/>
          <path id="Path_394" data-name="Path 394" d="M36.443,37.576a3.152,3.152,0,1,1,3.151-3.152,3.156,3.156,0,0,1-3.151,3.152m0-4.364a1.212,1.212,0,1,0,1.212,1.212,1.213,1.213,0,0,0-1.212-1.212" transform="translate(-11.097 -10.424)" style={{ fill: `${Additions_font_color}` }}/>
          <path id="Path_395" data-name="Path 395" d="M55.435,23.394H53.5a.97.97,0,1,1,0-1.939h1.939a.97.97,0,1,1,0,1.939" transform="translate(-17.509 -7.152)" style={{ fill: `${Additions_font_color}` }}/>
          <path id="Path_396" data-name="Path 396" d="M51.232,36.484H47.353a.97.97,0,1,1,0-1.939h3.879a.97.97,0,1,1,0,1.939" transform="translate(-15.461 -11.515)" style={{ fill: `${Additions_font_color}` }}/>
          <path id="Path_397" data-name="Path 397" d="M29.9,50.666a3.152,3.152,0,1,1,3.152-3.152A3.155,3.155,0,0,1,29.9,50.666m0-4.364a1.212,1.212,0,1,0,1.212,1.212A1.213,1.213,0,0,0,29.9,46.3" transform="translate(-8.915 -14.788)" style={{ fill: `${Additions_font_color}` }}/>
          <path id="Path_398" data-name="Path 398" d="M46.625,49.576H40.806a.97.97,0,0,1,0-1.939h5.819a.97.97,0,0,1,0,1.939" transform="translate(-13.279 -15.879)" style={{ fill: `${Additions_font_color}` }}/>
          <path id="Path_399" data-name="Path 399" d="M14.625,44.12a.97.97,0,0,1-.97-.969,57.506,57.506,0,0,1,9.071-31.069.97.97,0,0,1,1.634,1.046,55.569,55.569,0,0,0-8.765,30.023.97.97,0,0,1-.97.969" transform="translate(-4.552 -3.878)" style={{ fill: `${Additions_font_color}` }}/>
          <path id="Path_400" data-name="Path 400" d="M44.121,1.939a1.942,1.942,0,0,1,1.939,1.939V44.121a1.942,1.942,0,0,1-1.939,1.939H3.879a1.942,1.942,0,0,1-1.939-1.939V3.879A1.942,1.942,0,0,1,3.879,1.939H44.121m0-1.939H3.879A3.879,3.879,0,0,0,0,3.879V44.121A3.878,3.878,0,0,0,3.879,48H44.121A3.878,3.878,0,0,0,48,44.121V3.879A3.879,3.879,0,0,0,44.121,0" style={{ fill: `${Additions_font_color}` }}/>
        </g>
      </g>
    </g>
    <rect id="Rectangle_3000" data-name="Rectangle 3000" width="80" height="80" transform="translate(-6963 -2808)" fill="#fff" opacity="0"/>
  </g>
</svg>

            <p
              className=" size-1 Lite c-white my-0"
              style={{ color: `${Additions_font_color}` }}
            >
              {Feedweb_addition}
            </p>
          </div>
        </div>
        <svg width="145" height="49" viewBox="0 0 145 49" className="cart" 
        style={{
          zIndex:'1'
        }}>
          <defs>
            <style>{`.cc,.dd{fill:${Additions_stop_color};}.cc{font-size:16px;font-family:Roboto-Bold, Roboto;font-weight:700;}.dd{font-size:12px;font-family:Roboto-Light, Roboto;font-weight:300;letter-spacing:-0.025em;}}`}</style>
            <linearGradient
              id="a"
              x1="0.5"
              x2="0.5"
              y2="1"
              gradientUnits="objectBoundingBox"
            >
              <stop offset="0%" />
              <stop offset="100%" />
            </linearGradient>
          </defs>
          <Link
            activeClass="active"
            className="my-1"
            to="OrderList"
            spy={true}
            smooth={true}
            offset={-120}
            duration={300}
            style={{ cursor: "pointer" }}
          >
            <g transform="translate(-346 -826)">
              <rect
                style={{ fill: `${Additions_start_color}` }}
                width="145"
                height="49"
                rx="10"
                transform="translate(346 826)"
              />
              <rect
                style={{ fill: `${Additions_font_color}` }}
                width="139"
                height="43"
                rx="8"
                transform="translate(349 829)"
              />
              <text className="cc" transform="translate(404 862)">
                <tspan x="0" y="0">
                  {City !== undefined &&
                  City !== null &&
                  Object.keys(Order).length > 0
                    ? Ammount - OffRedux < FreeAbove
                      ? round(Ammount + City.delivery_price - OffRedux).toFixed(
                        2
                      )
                      : round(Ammount - OffRedux).toFixed(2)
                    : round(Ammount - OffRedux).toFixed(2)}{" "}
                  {Order_currency}
                </tspan>
              </text>
              <text className="dd" transform="translate(404 846)">
                <tspan x="0" y="0">
                  {Basket_text} ({Object.keys(Order).length})
                </tspan>
              </text>
              <g
                transform="translate(365.149 835.149)"
                style={{ fill: `${Additions_stop_color}` }}
              >
                <path
                  className="e"
                  d="M7.713,25.71a2.571,2.571,0,1,1,2.571-2.571A2.574,2.574,0,0,1,7.713,25.71ZM20.567,25.7a2.558,2.558,0,1,1,2.571-2.558A2.568,2.568,0,0,1,20.567,25.7Zm2.571-6.415H7.712a2.542,2.542,0,0,1-2.2-1.255,2.569,2.569,0,0,1-.045-2.563L7.2,12.328,2.571,2.572H0V0H4.2L5.412,2.572H24.436a1.281,1.281,0,0,1,1.119,1.9l-4.6,8.343a2.562,2.562,0,0,1-2.25,1.324H9.127L7.712,16.711H23.138v2.569ZM6.632,5.142h0l3.046,6.427H18.7l3.548-6.427Z"
                  transform="translate(1.286 2.57)"
                />
              </g>
            </g>
          </Link>
        </svg>
      </div>
    </div>
  );
}
